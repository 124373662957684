export default {
  _createWorkTimeRequest (inst, data) {
    inst.$target.loadSpin('start')

    $.ajax({
      url: Routes.work_times_path(),
      method: 'POST',
      data,
      error () {
        console.error(arguments)
      },
      complete () {
        inst.$target.loadSpin('stop')
      },
    })
  },

  _clearTimeRangeRequest (inst, data) {
    const god = this

    inst.$target.loadSpin('start')
    const appointmentsIds = data.appointmentsIds
    delete data.appointmentsIds

    $.ajax({
      url: Routes.clear_time_range_work_times_path(),
      method: 'POST',
      data,
      success () {
        if (appointmentsIds.length === 0) return
        god._showConfirmationModal(
          inst,
          true,
          T.transfer_appointments_to_the_waiting_list_head,
          T.transfer_appointments_to_the_waiting_list_body,
          () => {
            god._moveAppointmentsToWaitingList(inst, appointmentsIds)
            god._showConfirmationModal(inst, false)
          },
          () => {
            god._showConfirmationModal(inst, false)
          })
      },
      error () {
        console.error(arguments)
      },
      complete () {
        inst.$target.loadSpin('stop')
      },
    })
  },
}
