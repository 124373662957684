import EventManager from '../event_manager'

const constructorEvents = (ed) => {
  ed.on('keydown', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'backend')
    eventManager.checkContainsDynElementsFromSel()
    eventManager.attachHandleOnCtrlKey()
    eventManager.checkDynamicElement()
    eventManager.checkPressEnterKey()
    eventManager.checkBackspace()
    eventManager.checkPressDeleteKey()

    if (eventManager?.preventEvents?.length) {
      console.debug(eventManager)

      return false
    }
  })

  ed.on('keyup', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'backend')
    if (window.ublockElement) {
      window.ublockElement.setAttribute('contenteditable', false)
    }

    return false
  })

  ed.on('click', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'backend')
    eventManager.checkClick()
  })

  ed.on('drop', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'backend')
    eventManager.checkDrop()
  })
}

export default constructorEvents
