import {
  MODE_DEFAULT,
  DEFAULT_START_TIME,
  DEFAULT_END_TIME,
} from '../scheduleCommon/const.js'

import {
  overpopTemplate,
} from '../scheduleCommon/templates.js'

import {
  populateAppointment,
} from '../scheduleCommon/utils'

export default {

  _init (inst, data) {
    const god = this

    if (data) {
      inst._schedules = data.schedule
      inst._dates = Object.keys(data.schedule).sort()
      inst._date = data.date
      inst._cabinetIds = data
        .cabinet_ids
        .sort((cabinetId1, cabinetId2) => {
          const cabinet1 = AppCache.get('cabinet', cabinetId1)
          const cabinet2 = AppCache.get('cabinet', cabinetId2)

          return cabinet1.title.localeCompare(cabinet2.title)
        })
      inst._userIds = data.user_ids

      inst._usersHashMap = Object.freeze(inst._userIds.reduce((memo, id) => {
        memo[id] = AppCache.get('user', id)

        return memo
      }, Object.create(null)))

      inst._appointmentsHashMap = Object.create(null)
      inst._clientsHashMap = god._newClientsHashMap()

      let clientId
      data.appointments.forEach((appointment) => {
        inst._appointmentsHashMap[appointment.id] = populateAppointment(appointment)
        clientId = appointment.client_id
        inst._clientsHashMap.set(clientId, data.clients_with_unbilled_entries.includes(clientId))
      })

      inst.globalRange = {
        start: god._toMoment(inst, data.schedule_time_range.start),
        end: god._toMoment(inst, data.schedule_time_range.end),
      }
    } else god._setDefaultState(inst)

    inst._daysCount = inst._daysCount || 1
    inst.$grid = []
    inst.globalStart = inst.globalRange.start
    inst.globalEnd = inst.globalRange.end
  },

  _setDefaultState (inst) {
    const god = this

    inst._date = null
    inst._clientsHashMap = god._newClientsHashMap()
    inst._schedules =
      inst._usersHashMap =
      inst._appointmentsHashMap = Object.create(null)
    inst._dates =
      inst._cabinetIds =
      inst._userIds = []

    inst.globalRange = {
      start: god._toMoment(inst, DEFAULT_START_TIME),
      end: god._toMoment(inst, DEFAULT_END_TIME),
    }
  },

  attach (target, options) {
    const god = this

    const inst = {
      options: {
        ...god._defaults,
        ...options,
        step: 15,
      },
      $target: target,
      cache: Object.create(null),
      mode: MODE_DEFAULT,
      _reservations: Object.create(null),
    }

    target.data(god._propertyName, inst)
    target.empty()

    god._setHTML(inst)
    god._bindEvents(inst)

    god._init(inst, options.data)
    god._redrawAll(inst)

    if (options.mode) god._setMode(inst, options.mode)

    if (inst.mode === MODE_DEFAULT) {
      god._restoreState(inst)
    }

    const wsHandler = (data) => inst.options.tableVisibility && god._wsChannelUpdatesHandler(inst, data)

    Services.wsSubscriptions.appointment.connect(wsHandler)
    Services.wsSubscriptions.client.connect(wsHandler)
  },

  _setHTML (inst) {
    const god = this

    inst.$table = $('<table></table>')
    inst.$target.append(inst.$table)
    inst.$target.addClass(god._className)

    inst.$preview = $(overpopTemplate)
    inst.$preview.overpop({
      headerText: t('admission_information'),
      autoHide: true,
      afterHide () {
        inst._previewedAppointment = null
      },
      position: 'auto',
    })
  },
}
