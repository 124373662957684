import EventManager from '../event_manager'

const editEvents = (ed) => {
  ed.on('contextmenu', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'edit')
    eventManager.attachHandleContextMenu()
  })
  ed.on('click', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'edit')
    if (eventManager.checkClick() === false) return
    eventManager.setRangeOnClick()
    eventManager.setEmptyChars()
  })

  ed.on('keyup', (evt) => {
    if (window.ublockElement) {
      window.ublockElement.setAttribute('contenteditable', false)
      window.ublockElement = false
    }

    return false
  })

  ed.on('keydown', (evt) => {
    const eventManager = new EventManager(this, evt, ed, 'edit')

    //TODO key down event;
    eventManager.checkDynamicElement()
    eventManager.checkContainsDynElementsFromSel()
    eventManager.attachHandleOnDynamicEl()
    eventManager.attachHandleOnCtrlKey()
    eventManager.checkPressEnterKey()
    eventManager.checkBackspace()
    eventManager.checkPressDeleteKey()
    eventManager.checkPressTabKey()
    eventManager.checkPressArrow()

    if (eventManager.preventEvents.length > 0) {
      console.debug(eventManager.preventEvents)

      return false
    }
  })
}

export default editEvents
