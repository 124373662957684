import {
  MODE_CLIENT_INSERT,
  MODE_CLONE, MODE_DEFAULT,
  MODE_MOVE,
  MODE_PHONE_INSERT,
  MODE_WAITING_LIST_INSERT,
  MODE_WAITING_LIST_MOVE,
  DOCTOR,
  ADMINISTRATOR,
} from './const.js'

export default {
  MODE_DEFAULT,
  MODE_MOVE,
  MODE_CLONE,
  MODE_CLIENT_INSERT,
  MODE_PHONE_INSERT,
  MODE_WAITING_LIST_INSERT,
  MODE_WAITING_LIST_MOVE,

  previewPresets: {
    ADMINISTRATOR,
    DOCTOR,
  },
}
