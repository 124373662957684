import { AVAILABLE, CABINET, DATE, META, TIME, UNAVAILABLE } from '../scheduleCommon/const.js'

export default {

  _redrawAll (inst) {
    const god = this

    god._setGrid(inst)
    god._drawGrid(inst)
    god._redrawAllGridObjects(inst)
    god._drawTimeLine(inst, true)
    setTimeout(() => god._enableDragAndDrop(inst))
    Utils.updateTooltips(document.querySelector('#cabinet_schedule_block'))
  },

  _redrawGridElements (inst) {
    const god = this

    god._redrawAllGridObjects(inst)
    god._enableDragAndDrop(inst)
  },

  _setGrid (inst) {
    const god = this

    inst._grid = {}
    inst._gridTime = []
    inst._usersInCabinets = Object.create(null)

    const step = inst.options.step
    const time = inst.globalStart.clone()
    let stepTime
    let userIds
    let cabinet

    inst._grid.cabinet = [{ type: META, value: '' }]
    inst._grid.date = [{ type: META, value: '' }]

    while (time < inst.globalEnd) {
      stepTime = time.format('HH:mm')
      inst._grid[stepTime] = [{ type: TIME, value: stepTime }]
      inst._gridTime.push(stepTime)
      time.add(step, 'minutes')
    }

    inst._dates.forEach((date) => {
      inst._usersInCabinets[date] = Object.create(null)
      inst._cabinetIds.forEach((cabinetId) => {
        userIds = Object.keys(inst._schedules[date][cabinetId])

        if (inst.options.hideCabinetsWithoutSchedule && userIds.length === 0) {
          return
        }

        userIds.forEach((uid) => {
          if (!inst._usersInCabinets[date][uid]) {
            inst._usersInCabinets[date][uid] = []
          }
          inst._usersInCabinets[date][uid].push({
            cabinetId,
            workTime: inst._schedules[date][cabinetId][uid],
          })
        })

        inst._grid.date.push({ type: DATE, value: date })

        cabinet = AppCache.get('cabinet', cabinetId)

        inst._grid.cabinet.push({
          type: CABINET,
          value: cabinet.title,
          cabinet_id: cabinet.id,
          date,
        })

        inst._gridTime.forEach((time, index, gridTime) => {
          const availableUsers =
            userIds.filter((userId) => inst._schedules[date][cabinetId][userId]
              .some((timeRange) => time >= timeRange[0] && time < timeRange[1]))

          inst._grid[time].push({
            type: availableUsers.length > 0 ? AVAILABLE : UNAVAILABLE,
            value: '',
            cabinet,
            cabinet_id: cabinet.id,
            user_ids: availableUsers,
            time,
            date,
          })
        })
      })
    })

    god._mergeGridDates(inst)
  },

  _drawGrid (inst) {
    const god = this

    const table = inst.$table[0]
    const headRows = []
    const bodyRows = []
    let cells

    // rows with dates and cabinets
    headRows.push(`<tr>${inst._grid.date.map((date) => god._generateHeaderCell(inst, date)).join('')}</tr>`)
    headRows.push(`<tr>${inst._grid.cabinet.map((cabinet) => god._generateHeaderCell(inst, cabinet)).join('')}</tr>`)

    const useSeparator = god._useSeparatorFunction(inst)

    inst._gridTime.forEach((time) => {
      useSeparator(true) // reset separator closure

      cells = inst
        ._grid[time]
        .map((cell) => cell.type === TIME ? god._generateTimeCell(cell.value) : god._generateBodyCell(inst, cell, useSeparator()))
        .join('')
      bodyRows.push(`<tr>${cells}</tr>`)
    })

    table.innerHTML = `<thead>${headRows.join('')}</thead><tbody>${bodyRows.join('')}</tbody>`
    inst._thead = inst.$table.find('thead').get(0)
    inst._tbody = inst.$table.find('tbody').get(0)
    inst._grid.date.length === 1 ? table.classList.add('empty-table') : table.classList.remove('empty-table')
    inst.$table.scroll()
  },

  _generateHeaderCell (inst, cell) {
    switch (cell.type) {
      case CABINET:
        return (
          `
          <th>
            <div data-id="${cell.cabinet_id}" class="schedule-grid-header schedule-grid-header-cabinet">
              <span class="tooltip-bottom">${cell.value}</span>
            </div>
          </th>
          `
        )
      case DATE:
        return (
          `
          <th class="schedule-grid-date" style="flex: ${cell.flex};">
            <div class="schedule-grid-header">${moment(cell.value).format('ddd, DD MMMM')}</div>
          </th>
          `
        )
      case META:
        return (
          '<th><div class="schedule-grid-header"></div></th>'
        )
      default:
        throw new Error('incorrect cell type')
    }
  },

  _generateBodyCell (inst, cell, useSeparatorStyle) {
    const god = this

    const separator = useSeparatorStyle ? 'schedule-grid-with-divider' : ''
    const appointmentsContainer = god._generateAppointmentContainer(inst, cell)
    const usersIds = cell.user_ids.join('_')

    switch (cell.type) {
      case AVAILABLE:
        return (
          `
          <td class="schedule-grid-available ${separator}"
              data-date="${cell.date}"
              data-cabinet_id="${cell.cabinet_id}"
              data-user_ids="${usersIds}"
              data-time="${cell.time}">
            ${appointmentsContainer}
            <div class="schedule-grid-doctor-name-container">
              ${cell.user_ids.map((id, index) => `<span class="schedule-grid-doctor-name">${inst._usersHashMap[id].short_name}</span>`).join('')}
            </div>
            <span class="schedule-grid-cell-time">${cell.time}</span>
            <span class="schedule-grid-cell-paste">+</span>
          </td>
          `
        )
      case UNAVAILABLE:
        return (
          `
          <td class="schedule-grid-unavailable ${separator}"
              data-date="${cell.date}"
              data-cabinet_id="${cell.cabinet_id}"
              data-user_ids="${usersIds}"
              data-time="${cell.time}"
          >${appointmentsContainer}</td>
          `
        )
      default:
        throw new Error('incorrect cell type')
    }
  },
}
