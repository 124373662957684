import scheduleGrid from '../scheduleCommon/schedule_grid.js'
import init from './init.js'
import commonCore from '../scheduleCommon/core.js'
import core from './core.js'
import commonRender from '../scheduleCommon/render.js'
import render from './render.js'
import commonRenderAppointments from '../scheduleCommon/render_appointments.js'
import renderAppointments from './render_appointments.js'
import events from './events.js'
import ui from './ui.js'
import requests from './requests.js'
import commonRequests from '../scheduleCommon/requests.js'
import commonSockets from '../scheduleCommon/sockets.js'
import sockets from './sockets.js'
import commonModal from '../scheduleCommon/modal.js'
import modal from './modal.js'
import publicInterface from './public_interface.js'
import commonPublicInterface from '../scheduleCommon/public_interface.js'
import preview from '../scheduleCommon/preview'

class DoctorScheduleGrid {
  constructor (methods = {}) {
    this._defaults = {
      onMove () {},
      onClone () {},
    }

    Object.keys(methods).forEach((methodName) => {
      this[methodName] = methods[methodName]
    })
  }
}

let god = null

export const initDoctorScheduleGrid = () => {
  god = new DoctorScheduleGrid({
    _propertyName: 'doctor-schedule-grid',
    _className: 'doctor-schedule-grid',
    ...init,
    ...commonCore,
    ...core,
    ...commonRender,
    ...render,
    ...commonModal,
    ...modal,
    ...commonRenderAppointments,
    ...renderAppointments,
    ...events,
    ...ui,
    ...requests,
    ...commonRequests,
    ...commonSockets,
    ...sockets,
    ...publicInterface,
    ...commonPublicInterface,
    ...preview,
  })

  function getInstance (target) {
    return target.data(god._propertyName)
  }

  if (!$.scheduleGrid) $.scheduleGrid = scheduleGrid

  $.fn.doctorScheduleGrid = function (options) {
    if (typeof options === 'string') {
      const method = options + 'Method'
      if (typeof god[method] === 'function') {
        // get all arguments after first(methodName) and call method if exists
        const args = Array.prototype.slice.call(arguments, 1)
        args.unshift(getInstance(this))

        return god[method].apply(god, args)
      }
      throw new Error('incorrect public method: ' + method)
    } else {
      god.attach($(this), options || {})
    }
  }
}
