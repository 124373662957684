import {
  ADMINISTRATOR,
  MODE_CLIENT_INSERT,
  MODE_CLONE,
  MODE_DEFAULT,
  MODE_MOVE,
  MODE_PHONE_INSERT,
  MODE_WAITING_LIST_INSERT,
  MODE_WAITING_LIST_MOVE,
} from '../scheduleCommon/const.js'
import Services from '@/services/service_repository_instance'
import { createAdministratorOverpopIcons } from '@/vue_components/doctor_schedules/AdministratorOverpopIcons'

export default {
  _bindEvents (inst) {
    const god = this

    const { security } = Services
    const canManageAllSchedule = security
      .canManageScheduleTemplateScheduleTemplateBaseModel
    const canManageSelfSchedule = security
      .canManageSelfScheduleTemplateScheduleTemplateBaseModel

    if (
      inst.options.previewPreset === ADMINISTRATOR &&
      (canManageAllSchedule || canManageSelfSchedule)
    ) {
      inst.$table.on('mousedown', '.schedule-grid-available, .schedule-grid-unavailable', function (e) {
        if (!canManageAllSchedule) {
          const { currentUserId } = inst.options
          const targetUserId = parseInt(this.dataset.user_id, 10)

          if (!canManageSelfSchedule || currentUserId !== targetUserId) return
        }

        if (e.button === 0 && !e.target.closest('.schedule-grid-appointment')) {
          inst.cache.changeWorkTimeData = {
            startPosition: e.pageY,
            cellHeight: this.offsetHeight,
            numberSelected: 0,
            userId: this.dataset.user_id,
            user: inst._usersHashMap[this.dataset.user_id],
            date: this.dataset.date,
            formattedDate: moment(this.dataset.date).format('ddd, DD MMMM'),
            startTime: this.dataset.time,
            endTime: this.dataset.time,
            startCellTime: this.dataset.time,
            endCellTime: this.dataset.time,
            momentTime: god._toMoment(inst, this.dataset.time),
            cellsHashTable: { [this.dataset.time]: this },
          }
        } else if (inst.cache.changeWorkTimeData && (e.button === 1 || e.button === 2)) {
          god._clearTimeRange(inst)
        }
      })

      $(document).on('mouseup', function () {
        if (inst.cache.changeWorkTimeData) {
          if (inst.cache.changeWorkTimeData.startCellTime !== inst.cache.changeWorkTimeData.endCellTime) {
            god._changeWorkTime(inst)
          } else {
            god._clearTimeRange(inst)
          }
        }
      })

      inst.$table.on('mousemove', '.schedule-grid-available, .schedule-grid-unavailable', function (e) {
        if (inst.cache.changeWorkTimeData) {
          const data = inst.cache.changeWorkTimeData
          const selectedElementsCount = Math.floor((data.startPosition - e.pageY) / data.cellHeight)
          const selectedElementsModule = Math.abs(selectedElementsCount)

          if (data.numberSelected !== selectedElementsModule) {
            god._redrawTimeRange(inst, data, selectedElementsCount, selectedElementsModule)
          }
        }
      })

      inst.$target.on('click', '.waiting-list-count', function (e) {
        const userId = parseInt(this.dataset.id, 10)
        inst.options.setWaitingUser(inst.options.waitingListCount && inst.options.waitingListCount[userId] ? userId : null)
      })
    }

    inst.$table.on('click', '.schedule-grid-header-user', function (e) {
      if (inst._isDragging) return
      if (e.target.localName !== 'a' && inst.options.selectOneVisibleUser) {
        inst.options.selectOneVisibleUser(parseInt(e.currentTarget.getAttribute('data-id'), 10))
      }
    })

    inst.$table.on('click', '.schedule-grid-available', function (e) {
      if (inst._isDragging) return
      e.stopPropagation()
      e.preventDefault()
      inst.$preview.overpop('hide')

      const cellData = god._getCellData(this)

      if (~[MODE_DEFAULT, MODE_CLIENT_INSERT, MODE_PHONE_INSERT].indexOf(inst.mode)) {
        const params = AppCache.populate(cellData, { user_id: 'user' })
        params._worktime = god._getWorkTime(inst, cellData.date, cellData.user_id)
        params.status = 'need_approval'

        if (typeof inst.options.onFree === 'function') {
          inst.options.onFree(params, god._getParams(inst))
        }
      } else if (inst.mode === MODE_MOVE) {
        god._moveTo(inst, inst.cache.activeAppointment, cellData)
        god._setMode(inst, MODE_DEFAULT)
      } else if (inst.mode === MODE_CLONE) {
        god._cloneTo(inst, inst.cache.activeAppointment, cellData)
      } else if (inst.mode === MODE_WAITING_LIST_INSERT) {
        god._insertTo(inst, inst.cache.activeAppointment, cellData)
      } else if (inst.mode === MODE_WAITING_LIST_MOVE) {
        god._moveFromWaitingList(inst, inst.cache.activeAppointment, cellData)
      }
    })

    inst.$table.on('click', '.schedule-grid-reservation, .disabled-appointment', function (e) {
      e.stopPropagation()
      e.preventDefault()
    })

    inst.$table.on('click', '.schedule-grid-appointment:not(.disabled-appointment)', function (e) {
      if (inst._isDragging || inst.mode !== MODE_DEFAULT) return

      e.stopPropagation()
      e.preventDefault()

      const appointmentId = parseInt(this.dataset.id, 10)
      const appointment = inst._appointmentsHashMap[appointmentId]

      if (appointment === inst._previewedAppointment) {
        inst.$preview.overpop('hide')
      } else {
        inst._previewedAppointment = appointment
        inst._$previewedAppointment = $(this)
        inst.$preview
          .empty()
          .append(god.genOverpopContent(inst, appointment))
        const $select = inst.$preview
          .find('.schedule-grid-appointment-status-select')

        inst.$preview.find('.note-container, .schedule-grid-preview-entry_type').tooltip({ placement: 'bottom' })
        $select.select2Buttons()
        $select.val(appointment.status).change()

        if (!Services.security.canManageAppointment) {
          $select
            .next()
            .find('.select2buttons-option')
            .addClass('disabled')
        }

        inst.cache.activeAppointment = appointment
        inst.$preview.overpop('show', $(this))
        createAdministratorOverpopIcons('#AdministratorOverpopIcons', appointment.client?.id)
        PubSub.emit('page.specific.overpop.show')
        Services.telephony.reset()
        $select.on('change', function () {
          const appointment = inst.cache.activeAppointment
          appointment.status = $select.val()
          god._patchAppointment(inst, appointment)
          inst.$preview.overpop('hide')
        })
      }
    })

    inst.$target.on('scroll', function () {
      if (inst._previewedAppointment) {
        const offsetTop = inst._$previewedAppointment.offset().top
        if (offsetTop > $(window).height() || offsetTop < inst.$target.offset().top) {
          inst.$preview.overpop('hide')
        } else {
          inst.$preview.overpop('updatePosition')
        }
      }
    })

    $(document).off('keydown.doctorScheduleGrid')
    $(document).on('keydown.doctorScheduleGrid', function (e) {
      if (e.keyCode === 27) {
        inst.$preview.overpop('hide')
        if (inst.mode !== MODE_DEFAULT) {
          god._setMode(inst, MODE_DEFAULT)
        }
      }
    })

    god.bindPreviewEvents(inst)
  },
}
