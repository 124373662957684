import formatter from '@/lib/formatters/formatter'

/**
 * @typedef {{
 *   fullname: string,
 *   client_groups_string: string,
 *   emk_id: number,
 *   phone: string
 * }} PatchedClientFields
 *
 * Этот тип может работать не до конца - поля в client могут не отображаться в
 * подсказках, потому что jsdoc < typescript. Но так хотя бы можно увидеть поля,
 * посмотрев на сами типы.
 * @typedef {Record<string, any> & {
 *   client: Record<string, any> & PatchedClientFields,
 *   appointment_type?: string
 * }} PatchedPreviewAppointment
 */

/**
 * Устанавливает поля, используемые в шаблонах превью записи на приём,
 * устраняя расхождения между данными, приходящими по HTTP и сокетам.
 * Модифицирует переданную запись на приём.
 */
export class AppointmentPreviewAdapter {
  /**
   * @param {object} appointment
   * @param {{title: string, id: number}[]} [appointmentTypes]
   */
  constructor (appointment, appointmentTypes) {
    this._appointment = appointment
    this._appointmentTypes = appointmentTypes
    this._setMutators()
  }

  /**
   * @return {PatchedPreviewAppointment}
   * @throws {Error}
   */
  getWithAllPatchedFields () {
    return this.getWithPatchedFields(Array.from(this._mutatorsMap.keys()))
  }

  /**
   * @param {string[]} fieldNames
   * @return {PatchedPreviewAppointment}
   * @throws {Error}
   */
  getWithPatchedFields (fieldNames) {
    fieldNames.forEach((fieldName) => this.patchField(fieldName))

    return this._appointment
  }

  /**
   * @param {string} fieldName
   * @throws {Error}
   */
  patchField (fieldName) {
    if (!this._mutatorsMap.has(fieldName)) {
      throw new Error(`No appointment mutator found for field ${fieldName}`)
    }

    this._mutatorsMap.get(fieldName)()
  }

  /**
   * @private
   */
  _setClientFullName () {
    this._appointment.client.fullname = formatter.formatValue({
      ...this._appointment.client,
      secondName: this._appointment.client.second_name,
    }, ['client:fullName'])
  }

  /**
   * @private
   */
  _setClientGroups () {
    const groups = this._appointment.client.client_groups
    if (groups) {
      this._appointment.client.client_groups_string = groups
        .map((group) => group.title).join(', ')
    }
  }

  /**
   * @private
   */
  _setClientEmkId () {
    this.__setEmkByMedicalRecordIdCrutch()

    if (!this._appointment.client.emk_id) {
      // Если запись пришла по сокетам, то id ЕМК будет в этом поле
      this._appointment.client.emk_id = this._appointment.client.incremental_card_number
    }
  }

  /**
   * Я не понимаю почему такая реализация с медкартой,
   * но этот костыль позволяет сделать как надо
   * @private
   */
  __setEmkByMedicalRecordIdCrutch () {
    if (!this._appointment.client.medical_record_id) { return }

    this._appointment.client.emk_id = this._appointment.client.medical_record_id

    if (!gon.application.changeable_medcard_numbers) {
      this._appointment.client.emk_number = this._appointment.client.medical_record_id
    }
  }

  /**
   * @private
   */
  _setClientPhone () {
    // Если запись пришла по сокетам, то телефон может быть в двух других полях,
    // а не в phone
    const { client } = this._appointment
    client.phone = [
      client.formatted_phone,
      client.masked_phone,
      client.phone,
    ].find(Boolean)
  }

  /**
   * @private
   */
  _setAppointmentTypeTitle () {
    if (!this._appointmentTypes) {
      throw new Error('Appointment types should be defined to apply "_setAppointmentTypeTitle"')
    }

    const appointmentType = this._appointmentTypes
      .find(({ id }) => id === this._appointment.appointment_type_id)

    if (appointmentType) {
      this._appointment.appointment_type = appointmentType.title
    }
  }

  /**
   * @private
   */
  _setMutators () {
    const map = new Map()
    const fields = AppointmentPreviewAdapter.SUPPORTED_FIELDS
    this._bindMutator(map, fields.CLIENT_FULL_NAME, this._setClientFullName)
    this._bindMutator(map, fields.CLIENT_GROUPS_STRING, this._setClientGroups)
    this._bindMutator(map, fields.CLIENT_EMK_ID, this._setClientEmkId)
    this._bindMutator(map, fields.CLIENT_PHONE, this._setClientPhone)
    this._bindMutator(map, fields.APPOINTMENT_TYPE, this._setAppointmentTypeTitle)
    /**
     *
     * @type {Readonly<Map<string, Function>>}
     * @private
     */
    this._mutatorsMap = Object.freeze(map)
  }

  /**
   * @param {Map<string, Function>} map
   * @param {string} field
   * @param {Function} mutator
   * @private
   */
  _bindMutator (map, field, mutator) {
    map.set(field, mutator.bind(this))
  }
}

AppointmentPreviewAdapter.SUPPORTED_FIELDS = Object.freeze({
  CLIENT_FULL_NAME: 'fullname',
  CLIENT_PHONE: 'phone',
  CLIENT_GROUPS_STRING: 'client_groups_string',
  APPOINTMENT_TYPE: 'appointment_type',
  CLIENT_EMK_ID: 'emk_id',
})
