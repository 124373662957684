export default {
  setLoadDataMethod (inst, data) {
    const god = this

    data.date && (inst._date = data.date)
    data.daysCount && (inst._daysCount = data.daysCount)
    if (inst.options.tableVisibility) {
      god._init(inst, data)
      god._redrawAll(inst)
      god._triggerChange(inst)
    }
  },

  /**
   * @param inst
   * @param {{ onlyNewPatients: Boolean, statuses: Array, types: Array, clientGroups: Array }} filtrationData
   */
  setFiltrationMethod (inst, filtrationData) {
    inst.filtration = filtrationData
  },
}
