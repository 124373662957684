<template>
  <div
    id="AdministratorOverpopIcons"
    class="overpop-icons"
  >
    <div
      v-skeleton="loading"
      class="overpop-icons__container"
    >
      <i
        v-if="!loading && hasCreditDebtValue"
        v-tooltip="creditDebtTooltip"
        class="fad fa-fw fa-coins font-size-17 warning"
      />
      <i
        v-if="!loading && hasEntriesWithoutOrder"
        v-tooltip="entriesWithoutOrderTooltip"
        class="fad fa-fw fa-clipboard-list font-size-17 warning"
      />
      <i
        v-if="!loading && isClientWithoutProblems"
        v-tooltip="t('client_without_problems')"
        class="fad fa-fw fa-check font-size-17 success"
      />
    </div>
  </div>
</template>

<script>
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import {
  CREDIT_DEBT_DEFAULT_VALUE,
  getEntriesWithoutOrderDefaults,
} from '@/vue_components/doctor_schedules/AdministratorOverpopIcons/conts'
import { clientsPresenter } from '@/api_entities/clients/clientsPresenter'

export default {
  name: 'AdministratorOverpopIcons',
  mixins: [SpinnerHolder],

  props: {
    clientId: { type: Number, required: true },
  },

  data () {
    return {
      creditDebtValue: CREDIT_DEBT_DEFAULT_VALUE,
      entriesWithoutOrder: getEntriesWithoutOrderDefaults(),
    }
  },

  computed: {
    hasCreditDebtValue () {
      return this.creditDebtValue && this.creditDebtValue !== CREDIT_DEBT_DEFAULT_VALUE
    },

    hasEntriesWithoutOrder () {
      return Boolean(this.entriesWithoutOrder?.count)
    },

    isClientWithoutProblems () {
      return ![
        this.hasCreditDebtValue,
        this.hasEntriesWithoutOrder,
      ].some(Boolean)
    },

    creditDebtTooltip () {
      return t('credit_debt_tooltip', { value: this.creditDebtValue }) + Utils.currencySign()
    },

    entriesWithoutOrderTooltip () {
      return t('entries_without_order_tooltip', this.entriesWithoutOrder) + Utils.currencySign()
    },
  },

  created () {
    this.withSpinner(Promise.all([
      this.fetchCreditDebtValue(),
      this.fetchEntriesWithoutOrderInfo(),
    ]))
  },

  methods: {
    async fetchCreditDebtValue () {
      this.creditDebtValue = await clientsPresenter.fetchCreditDebtValue(this.clientId)
    },

    async fetchEntriesWithoutOrderInfo () {
      this.entriesWithoutOrder = await clientsPresenter.fetchEntriesWithoutOrderInfo(this.clientId)
    },
  },
}
</script>
