import { createStore } from '@/vue_components/store/store'
import { MODE_DEFAULT } from '@/plugins/schedule/scheduleCommon/const'

export default {
  _showInvalidReceptionTimeModal (inst) {
    inst.options.alertCall(true, {
      headerMessage: T.invalid_reception_time,
      message: T.working_time_is_shorter_than_the_reception_time,
    })

    createStore().commit('SET_MODE_GRID_SCHEDULE', MODE_DEFAULT)
  },

  _showConfirmationModal (inst, show, headerMessage, message, yesEvent, noEvent) {
    inst.options.confirmationModalCall(show, {
      headerMessage: headerMessage || '',
      message: message || '',
      yesEvent: yesEvent || (() => {}),
      noEvent: noEvent || (() => { inst.options.modalContent.showConfirmationModal = false }),
    })
  },

  _showCopyAppointmentWithServicesModal (inst, show, yesEvent, noEvent) {
    inst.options.copyAppointmentWithServicesModalCall(show, {
      yesEvent: yesEvent || (() => { }),
      noEvent: noEvent || (() => { }),
      rejectEvent () {
        inst.options.copyAppointmentWithServicesModalCall(false)
        inst.options.closeInsert()
      },
    })
  },
}
