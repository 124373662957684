let counter = 0
const instances = []

const defaults = {
  beforeShow () { return true },
  afterShow: null,
  afterHide () {},
  headerText: '',
  trigger: false,
  autoHide: false,
  escape: false,
  hideContainer: $(document),
  container: 'body',
  position: 'bottom',
}

function Overpop (elem, options) {
  this._options = $.extend({}, defaults, options)
  this._elem = elem
  this._cache = {}
  this._cache.visible = false
  this._cache.animating = false
  instances[counter] = this
  this._elem.data('overpopID', counter++)
  this._drawStructure()

  if (this._options.trigger) { this._bindTriggerMethod(this._options.trigger) }
}

$.extend(Overpop.prototype, {
  _drawStructure () {
    const container = $('<div>').addClass('overpop-container hidden-print')
    const header = $('<div>').addClass('overpop-header')
    const content = $('<div>').addClass('overpop-content')
    const dialog = $('<div>').addClass('overpop-dialog')
    const body = $('<div>').addClass('overpop-body')
    const arrowTop = $('<div>')
      .addClass('overpop-arrow')
      .addClass('overpop-arrow-top')
    const arrowBottom = $('<div>')
      .addClass('overpop-arrow')
      .addClass('overpop-arrow-bottom')
    const arrowLeft = $('<div>')
      .addClass('overpop-arrow')
      .addClass('overpop-arrow-left')
    const arrowRight = $('<div>')
      .addClass('overpop-arrow')
      .addClass('overpop-arrow-right')

    dialog.append(header).append(content)
    body.append(arrowLeft).append(dialog).append(arrowRight)
    container.append(arrowTop).append(body).append(arrowBottom)
    content.append(this._elem)
    this.$arrowTop = arrowTop
    this.$arrowBottom = arrowBottom
    this.$arrowLeft = arrowLeft
    this.$arrowRight = arrowRight

    const pluginContainer = (typeof this._options.container === 'string')
      ? $(this._options.container)
      : this._options.container

    pluginContainer.append(container)
    this._container = container
    this._header = header
    this._backdrop = $('<div style="position: fixed top:0 left:0 ' +
                       'right:0 bottom: 0 z-index: 1099"></div>')
      .appendTo($('body'))
      .hide()
  },

  _bindTriggerMethod (trigger) {
    const self = this
    trigger.on('click', function () {
      const trigger = $(this)
      if (self._cache.animating) return true

      if (self._cache.visible) {
        self._hideMethod()
      } else {
        self._showMethod(trigger)
      }
    })
  },

  _hideMethod () {
    const self = this

    // magic stop hide after calling show
    if (self._cache.animating) {
      self._container.clearQueue().stop(true, true).finish()
      self._cache.animating = false

      setTimeout(function () {
        self._hideMethod()
      }, 200)

      return
    }

    self._cache.animating = true
    self._container.fadeOut('fast', function () {
      self._cache.animating = false
      self._cache.visible = false
      self._options.afterHide(self._elem)
      if (self._options.autoHide) { self._unbindAutoHide() }
      self._backdrop.hide()
    })
  },

  _showMethod (trigger) {
    const self = this
    trigger = $(trigger)

    self._cache.lastTrigger = trigger

    if (self._options.beforeShow(self._elem, trigger) === false) {
      return
    }

    self._cache.animating = true
    self._header.text(self._options.headerText)

    self._updatePositionMethod(trigger)

    self._container.fadeIn('fast', function () {
      self._cache.animating = false
      self._cache.visible = true
      if (self._options.autoHide) {
        self._bindAutoHide()
      } else {
        self._backdrop.show()
      }

      if ($.isFunction(self._options.afterShow)) {
        self._options.afterShow(self._elem, trigger)
      }
    })

    if (this._options.escape) {
      $(document).off('keydown.overpop')

      $(document).on('keydown.overpop', function (e) {
        if (e.keyCode === 27) {
          self._hideMethod()
        }
      })
    }
  },

  _updatePositionMethod (trigger) {
    const self = this
    if (!trigger) trigger = self._cache.lastTrigger

    self.$arrowTop.hide()
    self.$arrowLeft.hide()
    self.$arrowRight.hide()
    self.$arrowBottom.hide()

    const arrowWidth = 15
    const triggerHeight = trigger.outerHeight()
    const triggerWidth = trigger.outerWidth()
    const triggerPosition = trigger.offset()
    const containerWidth = self._container.outerWidth()
    const containerHeight = self._container.outerHeight()
    const windowHeight = $(window).height()
    const windowWidth = $(window).width()

    if (self._options.position === 'bottom') {
      self._container.css({
        top: triggerPosition.top + triggerHeight,
        left: triggerPosition.left + (triggerWidth - containerWidth) / 2,
      })
      self.$arrowTop.show()
    } else if (self._options.position === 'auto') {
      const spaces = {}
      const top = triggerPosition.top
      const bottom = windowHeight - (triggerPosition.top + triggerHeight)
      const left = triggerPosition.left
      const right = windowWidth - (triggerPosition.left + triggerWidth)

      spaces[top] =
      {
        css: {
          top: triggerPosition.top - containerHeight - arrowWidth,
          left: triggerPosition.left + (triggerWidth - containerWidth) / 2,
        },
        arrow: self.$arrowBottom,
      }

      spaces[bottom] =
      {
        css: {
          top: triggerPosition.top + triggerHeight,
          left: triggerPosition.left + (triggerWidth - containerWidth) / 2,
        },
        arrow: self.$arrowTop,
      }

      let topEdgePosition = triggerPosition.top + (triggerHeight - containerHeight) / 2
      const bottomEdgePosition = topEdgePosition + containerHeight

      if (topEdgePosition < 0) {
        if (triggerPosition.top + triggerHeight >= windowHeight) {
          topEdgePosition = windowHeight / 2 - containerHeight / 2
        } else {
          topEdgePosition = (triggerHeight + triggerPosition.top) / 2 - containerHeight / 2
        }
      } else if (bottomEdgePosition > windowHeight) {
        if (triggerPosition.top <= 0) {
          topEdgePosition = windowHeight / 2 - containerHeight / 2
        } else {
          topEdgePosition = triggerPosition.top + (windowHeight - triggerPosition.top) / 2 - containerHeight / 2
        }
      }

      spaces[left] =
      {
        css: {
          top: topEdgePosition,
          left: triggerPosition.left - containerWidth - arrowWidth,
        },
        arrow: self.$arrowRight,
      }

      spaces[right] =
      {
        css: {
          top: topEdgePosition,
          left: triggerPosition.left + triggerWidth,
        },
        arrow: self.$arrowLeft,
      }

      const spaceKeysArray = [bottom, top, left, right]
      let maxSpace

      for (const key of spaceKeysArray) {
        const topEdgePosition = spaces[key].css.top
        const leftEdgePosition = spaces[key].css.left
        const rightEdgePosition = spaces[key].css.left + containerWidth
        const bottomEdgePosition = spaces[key].css.top + containerHeight
        if (topEdgePosition < 0 || leftEdgePosition < 0 ||
          rightEdgePosition > windowWidth ||
          bottomEdgePosition > windowHeight) continue

        maxSpace = spaces[key]
        break
      }

      if (!maxSpace) {
        maxSpace = spaces[spaceKeysArray.reduce((a, b) => Math.max(a, b))]
      }

      if (maxSpace.css.top + containerHeight > windowHeight) {
        // если поповер уехал под экран, поднять его так, чтобы он был виден целиком
        maxSpace.css.top = windowHeight - containerHeight
      }

      self._container.css(maxSpace.css)
      // стрелка отслеживает цель только если находится сбоку поповера
      if (maxSpace.arrow === self.$arrowLeft || maxSpace.arrow === self.$arrowRight) {
        maxSpace.arrow.css({
          // сдвигаем стрелку на разность в центрах триггера и поповера, чтобы стрелка была направлена на триггер
          // (центр триггера по y) - (центр поповера по y)
          top: (triggerPosition.top + triggerHeight / 2 - (maxSpace.css.top + containerHeight / 2)),
        })
      }
      maxSpace.arrow.show()
    }
  },

  _option (key, value) {
    this._options[key] = value
  },
  _bindAutoHide () {
    const self = this
    $(this._options.hideContainer).on(
      'click',
      { instance: self },
      this._handlerAutoHide
    )
  },
  _unbindAutoHide () {
    $(this._options.hideContainer).off('click', this._handlerAutoHide)
  },

  _handlerAutoHide (e) {
    const self = e.data.instance
    // wtf?
    if (e.originalEvent) {
      const originalTarget = $(e.originalEvent.target)
      const container = originalTarget.closest('.overpop-container')
      if (!container.length) {
        self._hideMethod()
      }
    }
  },
})

$.fn.overpop = function (options) {
  const args = arguments

  return this.each(function () {
    const self = $(this)

    if (typeof options === 'string') {
      const method = '_' + options + 'Method'
      const otherArgs = Array.prototype.slice.call(args, 1)
      const id = parseInt(self.data('overpopID'), 10)
      const instance = instances[id]

      if (!instance) {
        console.warn('OVERPOP INSTANCE:', new Error('not initialized instance').stack)

        return undefined
      }

      if (!instance[method]) {
        console.warn('OVERPOP INSTANCE METHOD:', new Error('Unknown method ' + options).stack)

        return undefined
      }

      instance[method].apply(instance, otherArgs)
    } else {
      return new Overpop(self, options || {})
    }
  })
}
