export default {

  _showUserSelectModal (inst, show, userIds, applyEvent, rejectEvent) {
    if (show) {
      if (userIds.length === 1) {
        applyEvent(userIds[0])

        return
      }

      inst.options.userSelectModalCall(true, {
        userIds: userIds,
        selectedUserId: userIds.slice(0, 1),
        applyEvent,
        rejectEvent: rejectEvent || (() => {
          inst.options.userSelectModalCall(false)
        }),
      })
    } else {
      inst.options.userSelectModalCall(false)
    }
  },
}
