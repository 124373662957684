import { CANCEL_ACTION, MODE_DEFAULT, RESERVATION_PACKET, RESERVE_ACTION } from './const.js'

export default {
  setCacheRequestMethod (inst, request) {
    if (inst.cache.request) {
      inst.cache.request.abort()
      inst.cache.request = null
    }

    inst.cache.request = request
  },

  setCurrentClinicIdMethod (inst, clinicId) {
    inst.options.currentClinicId = clinicId
  },

  optionMethod (inst, options) {
    const god = this

    inst.options = { ...inst.options, ...options }
    if (options.mode) god._setMode(inst, options.mode)
    inst.options.tableVisibility && god._redrawAll(inst)
  },

  setDaysCountMethod (inst, count) {
    inst._daysCount = count || 1
  },

  getDaysCountMethod (inst) {
    return inst._daysCount
  },

  setActiveAppointmentMethod (inst, appointment) {
    inst.cache.activeAppointment = appointment
  },

  setTableVisibilityMethod (inst, flag) {
    inst.options = { ...inst.options, tableVisibility: flag }
  },

  setUsersMethod (inst, userIds) {
    inst._userIds = userIds
  },

  setDateMethod (inst, date) {
    if (inst._date !== date) {
      inst._date = date
    }
  },

  showLoadingSpinnerMethod (inst, show) {
    inst.$target.loadSpin(show ? 'start' : 'stop')
  },

  getDateMethod (inst) {
    return moment(inst._date).toDate()
  },

  nextPeriodMethod (inst) {
    inst._date = Utils.dateJStoRuby(moment(inst._date).add(inst._daysCount, 'days').toDate())
  },

  prevPeriodMethod (inst) {
    inst._date = Utils.dateJStoRuby(moment(inst._date).subtract(inst._daysCount, 'days').toDate())
  },

  cancelPasteMethod (inst) {
    const god = this

    god._clearState()
    inst.options.tableVisibility && god._setMode(inst, MODE_DEFAULT)
  },

  redrawMethod (inst) {
    const god = this

    if (inst.options.tableVisibility) {
      god._setGrid(inst)
      god._drawGrid(inst)
    }
  },

  reserveMethod (inst, data) {
    Services.wsSubscriptions.appointment.send({
      type: RESERVATION_PACKET,
      action: RESERVE_ACTION,
      data,
    })
  },

  cancelReserveMethod (inst, data) {
    Services.wsSubscriptions.appointment.send({
      type: RESERVATION_PACKET,
      action: CANCEL_ACTION,
      data,
    })
  },

  getCacheMethod (inst) {
    return inst.cache
  },
}
