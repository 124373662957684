import { createStore } from '@/vue_components/store/store'
import { getSuggestionAddressCity } from '@/helpers/getSuggestionAddressCity'

(function ($) {
  $.fn.suggestionApi = function (params) {
    params = params || {}
    const scope = params.scope
    const token = gon.application.suggestion_api

    if (token.length < 10 || !token.length) {
      return
    }

    const vxStore = createStore()
    vxStore.dispatch('globalCatalogs/nsiStore/russianSubjectsDictionaryStore/vxLoadRussianSubjects')
    /**  @type {(title) => { id?: number} } getRussianSubject */
    const getRussianSubject =
      vxStore.getters['globalCatalogs/nsiStore/russianSubjectsDictionaryStore/vxGetRussianSubjectMethod']

    if (params.object === 'company') {
      this.suggestions({
        token,
        type: 'PARTY',
        count: 5,
        deferRequestBy: 300,
        /* Вызывается, когда пользователь выбирает одну из подсказок */
        onSelect (suggestion) {
          const data = suggestion.data
          if (!data) {
            return
          }

          const fio = data.management ? data.management.name.split(' ') : []

          scope.find('#suggestion_company_search').val('')
          scope.find('#company_title').val(data.name.full)
          scope.find('#company_type_of_company').val((data.opf && data.opf.short) || '')
          scope.find('#company_individual_tax_number').val(data.inn || '')
          scope.find('#company_kpp').val(data.kpp || '')
          scope.find('#company_ogrn').val(data.ogrn || '')
          scope.find('#company_okved').val(data.okved || '')
          scope.find('#company_registration_date').val($.datepicker.formatDate('dd.mm.yy', new Date(data.ogrn_date || '')))

          if (data.address && data.address.data) {
            const address = data.address.data
            scope.find('#company_address_formal_index').val(address.postal_code || '')
            scope.find('#company_address_formal_country').val(address.country || '')
            scope.find('#company_address_formal_region').val(address.region_with_type || '')
            scope.find('#company_address_formal_area').val(address.city_area || address.area_with_type || '')
            scope.find('#company_address_formal_city').val(address.city_with_type || address.settlement_with_type || '')
            scope.find('#company_address_formal_street').val(address.street_with_type || '')
            scope.find('#company_address_formal_house').val(address.house || '')
            scope.find('#company_address_formal_flat').val(address.flat || '')
            scope.find('#company_note').val('')
            scope.find('#address_fact').find('input').each(function () { $(this).val('') })
            scope.find('#concacts').find('input').each(function () { $(this).val('') })

            scope.find('#company_address_formal_nsi_russian_subject_id').val(getRussianSubject(address.region)?.id || '')
          } else {
            scope.find('#address_formal').find('input').each(function () { $(this).val('') })
            scope.find('#address_fact').find('input').each(function () { $(this).val('') })
            scope.find('#concacts').find('input').each(function () { $(this).val('') })
          }

          if (data.management) {
            scope.find('#company_head_surname').val(fio[0] || '')
            scope.find('#company_head_name').val(fio[1] || '')
            scope.find('#company_head_second_name').val(fio[2] || '')
            scope.find('#company_head_position').val(data.management.post || '')
            scope.find('#company_head_operates_by').val('')
          } else {
            scope.find('#company_head_surname').val('')
            scope.find('#company_head_name').val('')
            scope.find('#company_head_second_name').val('')
            scope.find('#company_head_position').val('')
            scope.find('#company_head_operates_by').val('')
          }
        },
      })
    }

    if (params.object === 'address') {
      this.suggestions({
        token,
        type: 'ADDRESS',
        count: 5,
        deferRequestBy: 300,
        /* Вызывается, когда пользователь выбирает одну из подсказок */
        onSelect (suggestion) {
          const data = suggestion.data
          if (!data) {
            return
          }

          const houseWithBlock = [
            data.house,
            data.block_type_full,
            data.block,
          ].join(' ')

          scope.find('#suggestion_client_address_search').val('')
          scope.find('#client_address_index').val(data.postal_code || '')
          scope.find('#client_address_country').val(data.country || '')
          scope.find('#client_address_region').val(data.region_with_type || '')
          scope.find('#client_address_area').val(data.city_area || data.area_with_type || '')
          scope.find('#client_address_city').val(getSuggestionAddressCity(data))
          scope.find('#client_address_street').val(data.street_with_type || '')
          scope.find('#client_address_house').val(houseWithBlock || '')
          scope.find('#client_address_flat').val(data.flat || '')

          scope.find('#client_address_nsi_russian_subject_id').val(getRussianSubject(data.region)?.id || '')
        },
      })
    }

    if (params.object === 'referral_address') {
      this.suggestions({
        token,
        type: 'ADDRESS',
        count: 5,
        deferRequestBy: 300,
        /* Вызывается, когда пользователь выбирает одну из подсказок */
        onSelect (suggestion) {
          const data = suggestion.data
          if (!data) {
            return
          }

          const houseWithBlock = [
            data.house,
            data.block_type_full,
            data.block,
          ].join(' ')

          scope.find('#suggestion_client_address_search').val('')
          scope.find('#referral_address_attributes_index').val(data.postal_code || '')
          scope.find('#referral_address_attributes_country').val(data.country || '')
          scope.find('#referral_address_attributes_region').val(data.region_with_type || '')
          scope.find('#referral_address_attributes_area').val(data.city_area || data.area_with_type || '')
          scope.find('#referral_address_attributes_city').val(data.city_with_type || data.settlement_with_type || '')
          scope.find('#referral_address_attributes_street').val(data.street_with_type || '')
          scope.find('#referral_address_attributes_house').val(houseWithBlock || '')
          scope.find('#referral_address_attributes_flat').val(data.flat || '')

          scope.find('#referral_address_nsi_russian_subject_id').val(getRussianSubject(data.region)?.id || '')
        },
      })
    }

    if (params.object === 'passport') {
      this.suggestions({
        token,
        type: 'fms_unit',
        count: 3,
        deferRequestBy: 300,
        /* Вызывается, когда пользователь выбирает одну из подсказок */
        onSelect (suggestion) {
          const data = suggestion.data

          if (!data) {
            return
          }

          scope.find('#client_id_card_who_issue').val(data.name || '')
        },
      })
    }
  }
})(jQuery)
