import { APPOINTMENT_TRANSIT_STORAGE_KEY, MODE_DEFAULT, PASTE_MODE_ARRAY } from './const.js'

export default {

  _isElementInTheSchedule (inst, drawable) {
    return inst._userIds.indexOf(drawable.user_id) !== -1 &&
      inst.options.currentClinicId === drawable.clinic_id &&
      inst._dates.includes(drawable.date)
  },

  _setAppointmentTime (inst, appointment) {
    const god = this

    const momentTime = god._toMoment(inst, appointment.time, true)
    appointment._roughTime = momentTime.format('HH:mm')
    appointment._roughTimeUnix = momentTime.valueOf()
    appointment._endTime = god
      ._toMoment(inst, appointment.time)
      .add(appointment.duration, 'minutes')
      .format('HH:mm')

    return appointment
  },

  _newClientsHashMap () {
    const clientsHashMap = Object.create(null)

    return {
      get (clientId) {
        return clientsHashMap[clientId]
      },
      set (clientId, hasUnbilledEntries = false) {
        const clientState = clientsHashMap[clientId]

        if (clientState) {
          clientState.appointmentsCount = clientState.appointmentsCount + 1
        } else {
          clientsHashMap[clientId] = {
            appointmentsCount: 1,
            unbilledEntries: hasUnbilledEntries,
          }
        }
      },
      remove (clientId) {
        if (!clientsHashMap[clientId]) return

        const appointmentsCount = clientsHashMap[clientId].appointmentsCount

        if (appointmentsCount === 1) delete clientsHashMap[clientId]
        else clientsHashMap[clientId].appointmentsCount = appointmentsCount - 1
      },
    }
  },

  _toMoment (inst, time, round) {
    const hours = parseInt(time.split(':')[0], 10)
    const minutes = parseInt(time.split(':')[1], 10)

    let mTime = moment().startOf('day').hours(hours).minute(minutes)

    if (round) {
      let gridTime = inst.globalRange.start.clone().hours(mTime.get('hours'))
      const step = inst.options.step

      if (gridTime > mTime) {
        // need subtract while
        do {
          gridTime = gridTime.subtract(step, 'minutes')
        } while (gridTime > mTime)
      } else if (gridTime.get('minutes') < mTime.get('minutes')) {
        // need add while
        while (gridTime.clone().add(step, 'minutes') <= mTime) {
          gridTime.add(step, 'minutes')
        }
      }

      mTime = gridTime
    }

    return mTime
  },

  _setMode (inst, mode) {
    const god = this

    if (mode === MODE_DEFAULT) {
      inst.$target.removeClass('schedule-grid-mode-paste')
    } else if (~PASTE_MODE_ARRAY.indexOf(mode)) {
      inst.$target.addClass('schedule-grid-mode-paste')

      god._saveState(inst, mode)
    }

    inst.mode = mode

    // trigger change only if grid is completely set up
    if (inst._date) god._triggerChange(inst)
  },

  _saveState (inst, mode) {
    localStorage.setItem(
      APPOINTMENT_TRANSIT_STORAGE_KEY,
      JSON.stringify({
        appointment: inst.cache.activeAppointment,
        mode,
      }),
    )
  },

  _restoreState (inst) {
    const state = localStorage.getItem(APPOINTMENT_TRANSIT_STORAGE_KEY)

    if (state) {
      const { mode, appointment } = JSON.parse(state)
      const god = this

      inst.cache.activeAppointment = appointment
      god._setMode(inst, mode)
    }
  },

  _clearState () {
    localStorage.removeItem(APPOINTMENT_TRANSIT_STORAGE_KEY)
  },

  _triggerChange (inst) {
    const god = this

    if (typeof inst.options.onChange === 'function') {
      inst.options.onChange(god._getParams(inst))
    }
  },

  _getParams (inst) {
    return {
      date: inst._date || inst._dates[0],
      daysCount: inst._daysCount,
      mode: inst.mode,
      workingUsers: inst._usersHashMap,
    }
  },

  _filterAppointments (inst) {
    const params = inst.filtration

    Object.values(inst._appointmentsHashMap).forEach((appointment) => {
      appointment.filtered = params
        ? this.isFilteredAppointment(params, appointment)
        : true
    })
  },

  isFilteredAppointment (params, appointment) {
    let flag = true

    if (params.onlyNewPatients && flag) {
      const isNewClient = appointment.new_client ||
        (appointment.client && appointment.client.new_client)

      flag = isNewClient
    }

    if (params.statuses.length && flag) {
      flag = params.statuses.includes(appointment.status)
    }

    if (params.types.length && flag) {
      flag = params.types.includes(appointment.appointment_type_id)
    }

    if (params.clientGroups.length && flag) {
      flag = appointment.client.client_groups
        .some((clientGroup) => params.clientGroups.includes(clientGroup.id))
    }

    return flag
  },
}
