import {
  CELL_HEIGHT,
  CELL_MARGIN_BOTTOM,
  ADMINISTRATOR,
  GRID_ICONS_TIPPY_CONFIG,
} from '../scheduleCommon/const.js'

import {
  statusClass,
  statusIconClass,
} from '../scheduleCommon/css_classes.js'

import {
  confirmationCallIcons,
  reminderCallIcons,
} from '@/modules/telephony/css_classes'

import {
  genAppointment,
  genPlaceholder,
  genReservation,
} from '../scheduleCommon/templates.js'

import buildGridElement from '../scheduleCommon/grid_element.js'

export default {

  _redrawAllGridObjects (inst) {
    const god = this

    inst.$table
      .find('.schedule-grid-appointment-container, .schedule-grid-reservation-container, .schedule-grid-placeholder')
      .remove()

    god._filterAppointments(inst)
    god._setAppointmentGrid(inst)

    const step = inst.options.step
    var grid = inst.$apGrid
    var drawAppointments = {}
    var drawReservations = {}
    var cell
    var cellsAmount
    let userIdMap

    if (step > 5 && inst.options.previewPreset === ADMINISTRATOR) {
      userIdMap = new Map()
      let millisecondsDate

      for (let appointment of Object.values(inst._appointmentsHashMap)) {
        if (!userIdMap.has(appointment.user_id)) {
          userIdMap.set(appointment.user_id, new Map())
        }
        if (!userIdMap.get(appointment.user_id).has(appointment.date)) {
          userIdMap.get(appointment.user_id).set(appointment.date, [])
        }

        millisecondsDate = Date.parse(`${appointment.date} ${appointment.time}`)

        userIdMap
          .get(appointment.user_id)
          .get(appointment.date)
          .push({
            appointmentId: appointment.id,
            start: millisecondsDate / 60000,
            end: millisecondsDate / 60000 + appointment.duration,
          })
      }

      for (let idsMapValue of userIdMap.values()) {
        for (let appointment of idsMapValue.values()) {
          appointment.sort((a, b) => a.start - b.start)
        }
      }
    }

    const drawElement = (gridElement) => {
      if (!cell) return

      if (gridElement.isAppointment() && !drawAppointments[gridElement.id]) {
        var ap = inst._appointmentsHashMap[gridElement.id]

        cellsAmount = Math.ceil(ap.duration / step)

        var appTemplate = {...ap}
        appTemplate._height = cellsAmount * CELL_HEIGHT + cellsAmount - CELL_MARGIN_BOTTOM
        ap._hasOrder = (ap.order_id > 0)

        appTemplate.sinkLeftClass = 'hvr-sink-left'
        appTemplate.statusClass = statusClass[ap.status] || ''
        appTemplate.iconClass = statusIconClass[ap.status] || ''
        appTemplate.onlineRecordingSourceClass = ap.appointment_source_id !== 1 ? 'online-recording-source' : ''
        const clientState = inst._clientsHashMap.get(ap.client_id)
        appTemplate.hasUnbilledEntries = clientState && clientState.unbilledEntries
        appTemplate.confirmationCallStatus = confirmationCallIcons[ap.call_confirmation_status]
        appTemplate.confirmationCallTooltip = ap.call_confirmation_status
          ? t('enums.appointment.call_confirmation_status')[ap.call_confirmation_status]
          : ''
        appTemplate.reminderCallStatus = reminderCallIcons[ap.call_reminder_status]
        appTemplate.reminderCallTooltip = ap.call_reminder_status
          ? t('enums.appointment.call_reminder_status')[ap.call_reminder_status]
          : ''

        if (ap._recentlyUpdated) {
          appTemplate.blinkClass = 'hvr-buzz-out'
          ap._recentlyUpdated = false
        } else {
          appTemplate.blinkClass = ''
        }

        appTemplate.hiddenWindowClassTop = ''
        appTemplate.hiddenWindowClassBottom = ''

        if (step > 5 && inst.options.previewPreset === ADMINISTRATOR) {
          let timeArray = userIdMap.get(ap.user_id).get(ap.date)
          let appointmentTime

          for (let i = 0; i < timeArray.length; i++) {
            appointmentTime = timeArray[i]
            if (appointmentTime.appointmentId === ap.id) {
              if (appointmentTime.start % step !== 0) {
                appTemplate.hiddenWindowClassTop = 'appointment-with-hidden-window-in-the-schedule-top'
              }
              if (appointmentTime.end % step !== 0) {
                appTemplate.hiddenWindowClassBottom = 'appointment-with-hidden-window-in-the-schedule-bottom'
              }
              if (i !== 0 && timeArray[i - 1].end >= appointmentTime.start) {
                appTemplate.hiddenWindowClassTop = ''
              }
              if (i !== timeArray.length - 1 && timeArray[i + 1].start <= appointmentTime.end) {
                appTemplate.hiddenWindowClassBottom = ''
              }
            }
          }
        }

        god._tryAddTypeIndicator(appTemplate, ap)

        cell.innerHTML += genAppointment(appTemplate)
        drawAppointments[gridElement.id] = true
        cell.closest('td').classList.add('schedule-grid-not-empty')
      } else if (gridElement.isReservation() && !drawReservations[gridElement.id]) {
        var res = inst._reservations[gridElement.id]
        cellsAmount = Math.ceil(res.duration / step)

        var resTemplate = Object.assign({}, res)
        resTemplate._height = cellsAmount * CELL_HEIGHT +
          cellsAmount - CELL_MARGIN_BOTTOM
        resTemplate._endTime = god
          ._toMoment(inst, res.time)
          .add(res.duration, 'minutes')
          .format('HH:mm')

        cell.innerHTML += genReservation(resTemplate)
        drawReservations[gridElement.id] = true
      } else {
        // draw placeholder if need
        if (cell && !gridElement.isIgnore()) {
          cell.innerHTML += genPlaceholder()
        }
      }
    }

    Object.keys(grid).forEach(function (date) {
      Object.keys(grid[date]).forEach(function (userId) {
        Object.keys(grid[date][userId]).forEach(function (time) {
          var cellId = god._generateGridSelector(date, userId, time)
          cell = document.getElementById(cellId)
          var map = grid[date][userId][time]
          map.forEach(drawElement)
        })
      })
    })
    inst.$target.loadSpin('stop')
    god._focusOnFlickeringAppointment(inst)
    Utils.epicUpdateTooltipsContainer({
      selector: inst._tbody,
      extendDefaultConfig: GRID_ICONS_TIPPY_CONFIG,
    })
  },

  _setAppointmentGrid (inst) {
    const god = this
    var drawable = god._getDrawableElements(inst)
    inst.$apGrid = []
    var grid = inst.$apGrid

    drawable.forEach(function (ap) {
      var cellsAmount = Math.ceil(ap.duration / inst.options.step)
      var i, j

      if (!grid[ap.date]) grid[ap.date] = []
      if (!grid[ap.date][ap.user_id]) grid[ap.date][ap.user_id] = []
      if (!grid[ap.date][ap.user_id][ap._roughTime]) {
        grid[ap.date][ap.user_id][ap._roughTime] = []
      }

      var freePlace = grid[ap.date][ap.user_id][ap._roughTime]
        .findIndex(function (e) {
          return e.isFree()
        })

      var apIndex
      var gridElement = buildGridElement(ap.__sg_type, ap.id)
      if (freePlace !== -1) {
        apIndex = freePlace
        grid[ap.date][ap.user_id][ap._roughTime][freePlace] = gridElement
      } else {
        apIndex = grid[ap.date][ap.user_id][ap._roughTime]
          .push(gridElement) - 1
      }

      var timeM = god._toMoment(inst, ap._roughTime)
      var time = ''

      for (i = 1; i < cellsAmount; i++) {
        timeM.add(inst.options.step, 'minutes')
        time = timeM.format('HH:mm')
        if (!grid[ap.date][ap.user_id][time]) {
          grid[ap.date][ap.user_id][time] = []
        }

        var affectedTime = grid[ap.date][ap.user_id][time]
        var affectedIndex = affectedTime.length

        for (j = affectedIndex; j < apIndex; j++) {
          affectedTime.push(buildGridElement('free'))
        }
        grid[ap.date][ap.user_id][time][apIndex] = gridElement.clone()
      }
    })

    var maxLength

    for (var date in grid) {
      if (!grid.hasOwnProperty(date)) continue
      for (var userId in grid[date]) {
        if (!grid[date].hasOwnProperty(userId)) continue
        // find length of row
        maxLength = -1
        for (var time in grid[date][userId]) {
          if (!grid[date][userId].hasOwnProperty(time)) continue
          maxLength = Math.max(grid[date][userId][time].length, maxLength)
        }

        var schedule = grid[date][userId]

        var lengths = []

        for (var k = 1; k < 5; k++) {
          for (var length = maxLength; length > 0; length--) {
            var timesWithLength = []
            // find all schedule time rows with specific length
            for (time in schedule) {
              if (!schedule.hasOwnProperty(time)) continue
              if (schedule[time].length === length) {
                timesWithLength.push(schedule[time])
              }
            }

            var foundMax

            timesWithLength.forEach(function (times) {
              foundMax = -1
              times.forEach(function (appId) {
                if (lengths[appId]) {
                  foundMax = Math.max(lengths[appId], foundMax)
                }
              })

              times.forEach(function (appId) {
                if (appId.isIgnore() || appId.isFree()) return
                lengths[appId] = lengths[appId]
                  ? Math.max(length, lengths[appId], foundMax)
                  : Math.max(foundMax, length)
              })
            })
          }
        }

        for (time in schedule) {
          if (!schedule.hasOwnProperty(time)) continue
          if (schedule[time].length < maxLength) {
            var busyLength = lengths[schedule[time][0]]

            for (var i = schedule[time].length; i < maxLength; i++) {
              schedule[time][i] = (i >= busyLength)
                ? buildGridElement('ignore')
                : buildGridElement('free')
            }
          }
        }
      }
    }
  },

  _focusOnFlickeringAppointment (inst) {
    if (inst.options.flickeringAppointmentId === null) return

    const appointmentId = inst.options.flickeringAppointmentId

    inst.options.flickeringAppointmentId = null

    const scheduleContainer = inst.$target.get(0)
    scheduleContainer.scrollTo(0, 0)

    const appointment = inst
      .$table
      .find(`.schedule-grid-appointment[data-id="${appointmentId}"]`)
      .addClass('hvr-buzz-out')
      .get(0)

    if (!appointment) return

    const scheduleContainerScrollHeight = scheduleContainer.scrollHeight
    const { y: scheduleContainerOffsetTop, height: scheduleContainerHeight } =
      scheduleContainer.getBoundingClientRect()

    if (scheduleContainerScrollHeight === scheduleContainerHeight) return

    const { y: absoluteHeightRangeStart } = inst._tbody.getBoundingClientRect()
    const absoluteHeightRangeEnd = scheduleContainerOffsetTop + scheduleContainerHeight

    const { y: appointmentOffsetTop } = appointment.getBoundingClientRect()
    const targetAbsoluteAppointmentPosition =
      absoluteHeightRangeStart + ((absoluteHeightRangeEnd - absoluteHeightRangeStart) / 2)

    scheduleContainer.scrollTo(
      0,
      appointmentOffsetTop - targetAbsoluteAppointmentPosition + scheduleContainerOffsetTop,
    )
  },

  _getDrawableElements (inst) {
    const god = this

    const drawableElements = []

    Object.values(inst._appointmentsHashMap).forEach((appointment) => {
      drawableElements.push({...(god._setAppointmentTime(inst, appointment)), __sg_type: 'appointment'})
    })

    Object.values(inst._reservations).forEach((reservation) => {
      drawableElements.push({...(god._setAppointmentTime(inst, reservation)), __sg_type: 'reservation'})
    })

    // sort by time and duration
    drawableElements.sort(function (ap1, ap2) {
      var scomp = Utils.stringsCompare
      var step = inst.options.step

      if (ap1.date !== ap2.date) {
        return scomp(ap1.date, ap2.date)
      }

      if (ap1.user_id !== ap2.user_id) {
        return ap1.user_id - ap2.user_id
      }

      if (ap1._roughTimeUnix !== ap2._roughTimeUnix) {
        return ap1._roughTimeUnix - ap2._roughTimeUnix
      }

      if (ap1.duration !== ap2.duration) {
        return Math.ceil(ap2.duration / step) -
          Math.ceil(ap1.duration / step)
      }

      if (ap1.client && ap2.client) {
        return scomp(ap1.client.fullname, ap2.client.fullname)
      }

      if (ap1.__sg_type !== ap2.__sg_type) {
        return scomp(ap1.__sg_type, ap2.__sg_type)
      }

      return 0 // dont care
    })

    return drawableElements
  },

  _generateAppointmentContainer (inst, cell) {
    const god = this

    return (
      `<div id="${god._generateGridSelector(cell.date, cell.user_id, cell.time)}" ` +
      'class="schedule-grid-appointments-container schedule-grid-container-available"></div>'
    )
  },

  _generateGridSelector (date, userId, time) {
    return 'user_appointment_container_' + date + '_' + userId + '_' + time
  },
}
