//Hide prompts

(function ($) {
  var storeName = 'prompt-notices'

  function _btnCloseHandler ($this) {
    $this.find('.prompt-notice-close-btn').click(function () {
      var $container = $(this).closest('.prompt-notice-container')
      var $containerId = $container.attr('id')
      var storeMap = store.get(storeName) || {}

      storeMap[$containerId] = 'hide'
      store.set(storeName, storeMap)

      $container.fadeOut()
    })
  }

  $.fn.promptNotice = function () {
    var promptContainerIds = []

    $('.prompt-notice-container').each(function () {
      promptContainerIds.push($(this).attr('id'))
    })

    var storeMap = store.get(storeName) || {}

    promptContainerIds.forEach(function (promptContainerId) {
      if (!storeMap[promptContainerId] || storeMap[promptContainerId] !== 'hide') {
        $('#' + promptContainerId).show()
      }
    })
    _btnCloseHandler(this)
  }
}(jQuery))
