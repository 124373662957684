function getClientSystemGroupNames (appointment) {
  return appointment.client.client_groups
    .reduce((namesString, group) => {
      return namesString + `${group.system_name || ''} `
    }, '')
    .trim()
}

export const genAppointment = (a) => {
  if (!a.filtered) {
    return genDisabledAppointment(a)
  }

  const firstEntryTypeTitle = a.entry_types.length > 0 ? a.entry_types[0].title : ''
  const byDms = a.by_dms ? 'by_dms' : ''
  const doctorName = getDoctorName(a)
  const unbilledEntriesIcon = getUnbilledEntriesIcon(a)
  const appointmentClasses = [
    a.sinkLeftClass,
    a.hiddenWindowClassBottom,
    a.onlineRecordingSourceClass,
    a.hiddenWindowClassTop,
    a.statusClass,
    a.blinkClass,
  ]
    .filter(Boolean)
    .join(' ')

  const confirmationCallStatus = getConfirmationCallStatus(a)
  const reminderCallStatus = getReminderCallStatus(a)
  const onlineRecording = getOnlineRecording(a)
  const newClient = getNewClient(a)
  const appointmentTypeIndicator = getAppointmentTypeIndicator(a)
  const clientSystemGroups = getClientSystemGroupNames(a)

  return (
    `
    <div class="schedule-grid-appointment-container ${byDms} ${clientSystemGroups}">
      <div class="schedule-grid-appointment ${appointmentClasses}"
           data-id="${a.id}"
           data-date="${a.date}"
           style="height:${a._height}px">
        <div class="schedule-grid-appointment-surname-container">
          <span class="schedule-grid-appointment-status-icon ${a.iconClass}"></span>
          <span class="schedule-grid-appointment-surname">${a.client.surname}&nbsp;</span>
          <span class="schedule-grid-appointment-name">${getInitials(a)}</span>
        </div>
        <span class="schedule-grid-appointment-time-start hidden-print">${a.time}-</span>
        <span class="schedule-grid-appointment-time-end hidden-print">${a._endTime}</span>
        <div class="schedule-grid-preview-entry_type cut hidden-print">${firstEntryTypeTitle}&nbsp;</div>
        <span class="schedule-grid-appointment-note hidden-print">${a.note || ''}</span>
        ${doctorName}
        <div class="schedule-grid-appointment-icons-container">
          ${onlineRecording}
          ${unbilledEntriesIcon}
          ${confirmationCallStatus}
          ${reminderCallStatus}
          ${newClient}
        </div>
        ${appointmentTypeIndicator}
      </div>
    </div>
    `
  )
}

export const genReservation = (r) => {
  return `
  <div class="schedule-grid-reservation-container">
    <div class="schedule-grid-reservation hvr-sink-left"
         data-id="${r.id}"
         data-date="${r.date}"
         style="height:${r._height}px">
      <div class="loading-ellipsis cut">${t('recording_in_progress')}</div>
      <div class="cut">${r.initiator.surname}</div>
    </div>
  </div>
  `
}

export const genPlaceholder = () => '<div class="schedule-grid-placeholder"></div>'

export const overpopTemplate = '<div class="schedule-grid-preview"></div>'

export const genDisabledAppointment = (a) => {
  return `<div class="schedule-grid-appointment-container">
            <div
                class="schedule-grid-appointment disabled-appointment"
                style="height: ${a._height}px"
                ></div>
          </div>`
}

function getDoctorName ({ showDoctorName, user }) {
  if (!showDoctorName) return ''

  return `<div class="schedule-grid-appointment-doctor-name">${user.short_name}</div>`
}

function getUnbilledEntriesIcon ({ hasUnbilledEntries }) {
  if (!hasUnbilledEntries) return ''

  return `
  <div
    title="${t('client_has_unbilled_entries')}"
    class="tooltip-bottom schedule-grid-appointment-unbilled-entries fad fa-exclamation-circle"
  ></div>`
}

function getConfirmationCallStatus ({ confirmationCallStatus, confirmationCallTooltip }) {
  if (!confirmationCallStatus) return ''

  return `
  <div
    title="${confirmationCallTooltip}"
    class="tooltip-bottom schedule-grid-appointment-confirmation-call-status ${confirmationCallStatus}"
  ></div>`
}

function getReminderCallStatus ({ reminderCallStatus, reminderCallTooltip }) {
  if (!reminderCallStatus) return ''

  return `
  <div
    title="${reminderCallTooltip}"
    class="tooltip-bottom schedule-grid-appointment-reminder-call-status ${reminderCallStatus}"
  ></div>`
}

function getOnlineRecording ({ appointment_source_id: appointmentSourceId }) {
  if (appointmentSourceId === 1) return ''

  return `
  <div
    title="${t('client_widget.online_recording')}"
    class="tooltip-bottom fad fa-globe"
    style="margin: 0 2px 2px 2px;"
  ></div>`
}

function getNewClient (appointment) {
  if (!(appointment.new_client || (appointment.client && appointment.client.new_client))) return ''

  return `
  <div
    title="${t('new_client')}"
    class="tooltip-bottom fad fa-user"
    style="margin: 0 2px 2px 2px;"
  ></div>`
}

function getAppointmentTypeIndicator ({ appointmentTypeIndicatorColor }) {
  if (!appointmentTypeIndicatorColor) return ''

  return `
    <span class="appointment-type-indicator__wrapper">
      <span
        class="appointment-type-indicator__corner"
        style="background-color: ${appointmentTypeIndicatorColor}"
      />
    </span>`
}

/**
 * @param {Object} appointment
 * @return {string}
 */
function getInitials (appointment) {
  const { name, second_name: secondName } = appointment.client
  if (!gon.specific.need_initials || !name) { return '' }

  return `${name.slice(0, 1)}&nbsp;${secondName?.slice(0, 1)}&nbsp;`
}
