import { AppointmentPreviewAdapter } from '@/plugins/schedule/scheduleCommon/preview/appointment_preview_adapter'

const template = Handlebars.compile(
  `
    <div class="doctor-areas_schedule-grid_fullname"> {{ client.fullname }} </div>
    <div class="doctor-areas_schedule-grid_entries">
      {{#each entry_types}}
        <div class="entry-type-container">
          {{this.title}}
        </div>
      {{/each}}
    </div>
    <div class="doctor-areas_schedule-grid_note note-container prompt-notice">
      {{note}}
    </div>
    <button class="btn btn-primary btn-xs medical-record-button">
      ${t('medcard')}
    </button>
    {{#if _currentUserCanManageAppointment}}
    <button class="btn btn-primary btn-xs doctor-areas-appointment-status-button" value="serviced">
      ${t('reception.reception_complete')}
    </button>
    {{/if}}
  `
)

export default {
  genOverpopContent (inst, appointment) {
    const fieldsNeeded = [
      AppointmentPreviewAdapter.SUPPORTED_FIELDS.CLIENT_FULL_NAME,
      AppointmentPreviewAdapter.SUPPORTED_FIELDS.CLIENT_EMK_ID,
    ]
    const previewAdapter = new AppointmentPreviewAdapter(appointment)

    return template(Object.assign(previewAdapter.getWithPatchedFields(fieldsNeeded), {
      _currentUserCanManageAppointment: Services.security.canManageAppointment,
    }))
  },

  bindPreviewEvents (inst) {
    const preview = inst.$preview

    preview.on('click', '.medical-record-button', () => {
      inst.$preview.overpop('hide')
      const app = inst.cache.activeAppointment
      const url = Routes.medical_record_path(app.client.emk_id)

      Turbolinks.visit(url)
    })

    preview.on('click', '.doctor-areas-appointment-status-button', (e) => {
      preview.overpop('hide')
      const btnValue = e.target.value
      const app = inst.cache.activeAppointment
      if (app.status === btnValue) return
      app.status = btnValue
      this._patchAppointment(inst, app)
    })
  },
}
