export default {

  _workTimeUpdatesHandler (inst, packet) {
    const god = this

    let sendRequest = false

    if (packet.dates_with_changes.length) {
      packet.dates_with_changes.forEach((data) => {
        if (!sendRequest &&
          inst.options.currentClinicId === data.template.clinic_id &&
          inst._userIds.includes(data.template.user_id) &&
          data.dates.some((date) => inst._dates.includes(date))) {
          god._requestData(inst)
          sendRequest = true
        }
      })
    }

    if (!sendRequest && packet.days_with_changes.length) {
      let redraw = false

      packet.days_with_changes.forEach((data) => {
        if (inst.options.currentClinicId === +data.clinic_id &&
          inst._userIds.includes(data.user_id) &&
          inst._dates.includes(data.date)
        ) {
          inst._schedules[data.date][data.user_id] = data.merged_work_time
          redraw = true
        }
      })

      if (redraw) {
        inst.$target.loadSpin('start')
        god._updateGlobalTimeRange(inst)
        god._redrawAll(inst)
        inst.$target.loadSpin('stop')
        god._triggerChange(inst)
      }
    }
  },
}
