import { ADMINISTRATOR, CELL_HEIGHT, CELL_MARGIN_BOTTOM, GRID_ICONS_TIPPY_CONFIG } from '../scheduleCommon/const.js'

import { statusClass, statusIconClass } from '../scheduleCommon/css_classes.js'

import { genAppointment, genPlaceholder, genReservation } from '../scheduleCommon/templates.js'

import buildGridElement from '../scheduleCommon/grid_element.js'

export default {

  _redrawAllGridObjects (inst) {
    const god = this

    inst.$table
      .find('.schedule-grid-appointment-container, .schedule-grid-reservation-container, .schedule-grid-placeholder')
      .remove()

    god._filterAppointments(inst)
    god._setAppointmentGrid(inst)

    const step = inst.options.step
    const grid = inst.$apGrid
    const drawAppointments = {}
    const drawReservations = {}
    inst._drawableElements = inst._dates.reduce((memo, date) => {
      memo[date] = []

      return memo
    }, Object.create(null))

    let cell
    let cellsAmount
    let userIdMap

    if (step > 5 && inst.options.previewPreset === ADMINISTRATOR) {
      userIdMap = new Map()
      let millisecondsDate

      for (const appointment of Object.values(inst._appointmentsHashMap)) {
        if (!userIdMap.has(appointment.user_id)) {
          userIdMap.set(appointment.user_id, new Map())
        }
        if (!userIdMap.get(appointment.user_id).has(appointment.date)) {
          userIdMap.get(appointment.user_id).set(appointment.date, [])
        }

        millisecondsDate = Date.parse(`${appointment.date} ${appointment.time}`)

        userIdMap
          .get(appointment.user_id)
          .get(appointment.date)
          .push({
            appointmentId: appointment.id,
            start: millisecondsDate / 60000,
            end: millisecondsDate / 60000 + appointment.duration,
          })
      }

      for (const idsMapValue of userIdMap.values()) {
        for (const appointment of idsMapValue.values()) {
          appointment.sort((a, b) => a.start - b.start)
        }
      }
    }

    const drawElement = (gridElement) => {
      if (!cell) return

      if (gridElement.isAppointment() && !drawAppointments[gridElement.id]) {
        const ap = inst._appointmentsHashMap[gridElement.id]

        cellsAmount = Math.ceil(ap.duration / step)

        const appTemplate = { ...ap }
        appTemplate._height = cellsAmount * CELL_HEIGHT + cellsAmount - CELL_MARGIN_BOTTOM
        ap._hasOrder = (ap.order_id > 0)

        appTemplate.showDoctorName = true
        appTemplate.statusClass = statusClass[ap.status] || ''
        appTemplate.iconClass = statusIconClass[ap.status] || ''
        appTemplate.onlineRecordingSourceClass = ap.appointment_source_id !== 1 ? 'online-recording-source' : ''
        const clientState = inst._clientsHashMap.get(ap.client_id)
        appTemplate.hasUnbilledEntries = clientState && clientState.unbilledEntries

        if (ap._recentlyUpdated) {
          appTemplate.blinkClass = 'hvr-buzz-out'
          ap._recentlyUpdated = false
        } else {
          appTemplate.blinkClass = ''
        }

        appTemplate.hiddenWindowClassTop = ''
        appTemplate.hiddenWindowClassBottom = ''

        if (step > 5 && inst.options.previewPreset === ADMINISTRATOR) {
          const timeArray = userIdMap.get(ap.user_id).get(ap.date)
          let appointmentTime

          for (let i = 0; i < timeArray.length; i++) {
            appointmentTime = timeArray[i]
            if (appointmentTime.appointmentId === ap.id) {
              if (appointmentTime.start % step !== 0) {
                appTemplate.hiddenWindowClassTop = 'appointment-with-hidden-window-in-the-schedule-top'
              }
              if (appointmentTime.end % step !== 0) {
                appTemplate.hiddenWindowClassBottom = 'appointment-with-hidden-window-in-the-schedule-bottom'
              }
              if (i !== 0 && timeArray[i - 1].end >= appointmentTime.start) {
                appTemplate.hiddenWindowClassTop = ''
              }
              if (i !== timeArray.length - 1 && timeArray[i + 1].start <= appointmentTime.end) {
                appTemplate.hiddenWindowClassBottom = ''
              }
            }
          }
        }

        god._tryAddTypeIndicator(appTemplate, ap)

        cell.innerHTML += genAppointment(appTemplate)
        drawAppointments[gridElement.id] = true
        cell.closest('td').classList.add('schedule-grid-not-empty')
      } else if (gridElement.isReservation() && !drawReservations[gridElement.id]) {
        const res = inst._reservations[gridElement.id]
        const resTemplate = Object.assign({}, res)
        cellsAmount = Math.ceil(res.duration / step)
        resTemplate._height = cellsAmount * CELL_HEIGHT + cellsAmount - CELL_MARGIN_BOTTOM
        resTemplate._endTime = god
          ._toMoment(inst, res.time)
          .add(res.duration, 'minutes')
          .format('HH:mm')

        cell.innerHTML += genReservation(resTemplate)
        drawReservations[gridElement.id] = true
      } else {
        // draw placeholder if need
        if (cell && !gridElement.isIgnore()) {
          cell.innerHTML += genPlaceholder()
        }
      }
    }

    let cellId, map
    Object.keys(grid).forEach((date) => {
      Object.keys(grid[date]).forEach((cabinetId) => {
        Object.keys(grid[date][cabinetId]).sort().forEach((time) => {
          cellId = god._generateGridSelector(date, cabinetId, time)
          cell = document.getElementById(cellId)
          map = grid[date][cabinetId][time]
          map.forEach(drawElement)
        })
      })
    })

    inst.$table.find('.schedule-grid-appointment:not(.disabled-appointment), .schedule-grid-reservation')
      .each(function () { inst._drawableElements[this.dataset.date].push(this) })
    inst.$target.loadSpin('stop')
    Utils.epicUpdateTooltipsContainer({
      selector: inst._tbody,
      extendDefaultConfig: GRID_ICONS_TIPPY_CONFIG,
    })
  },

  _setAppointmentGrid (inst) {
    const god = this

    const grid = inst.$apGrid = {}

    god._getDrawableElements(inst).forEach((ap) => {
      const workTimes = inst._usersInCabinets[ap.date][ap.user_id]

      if (!workTimes) return

      let match = false
      let cabinetId

      for (let i = 0; i < workTimes.length; i++) {
        match = workTimes[i].workTime.some((dateRange) => {
          return ap._roughTime >= dateRange[0] && ap._roughTime < dateRange[1]
        })
        if (match) {
          cabinetId = workTimes[i].cabinetId
          break
        }
      }

      if (!match) return

      const cellsAmount = Math.ceil(ap.duration / inst.options.step)
      let i, j

      if (!grid[ap.date]) grid[ap.date] = {}
      if (!grid[ap.date][cabinetId]) grid[ap.date][cabinetId] = {}
      if (!grid[ap.date][cabinetId][ap._roughTime]) {
        grid[ap.date][cabinetId][ap._roughTime] = []
      }

      const cabinetAppointmentsMap = grid[ap.date][cabinetId]
      const freePlace = cabinetAppointmentsMap[ap._roughTime].findIndex((e) => e.isFree())
      const gridElement = buildGridElement(ap.__sg_type, ap.id)
      let apIndex

      if (freePlace !== -1) {
        apIndex = freePlace
        cabinetAppointmentsMap[ap._roughTime][freePlace] = gridElement
      } else {
        apIndex = cabinetAppointmentsMap[ap._roughTime].push(gridElement) - 1
      }

      const timeM = god._toMoment(inst, ap._roughTime)
      let time = ''

      for (i = 1; i < cellsAmount; i++) {
        timeM.add(inst.options.step, 'minutes')
        time = timeM.format('HH:mm')
        if (!cabinetAppointmentsMap[time]) cabinetAppointmentsMap[time] = []

        const affectedTime = cabinetAppointmentsMap[time]
        const affectedIndex = affectedTime.length

        for (j = affectedIndex; j < apIndex; j++) {
          affectedTime.push(buildGridElement('free'))
        }
        cabinetAppointmentsMap[time][apIndex] = gridElement.clone()
      }
    })

    let maxLength

    for (const date in grid) {
      if (!Object.prototype.hasOwnProperty.call(grid, date) || !grid[date]) { continue }
      for (const cabinetId in grid[date]) {
        if (!Object.prototype.hasOwnProperty.call(grid[date], cabinetId)) { continue }
        // find length of row
        maxLength = -1
        if (!grid[date][cabinetId]) { continue }
        for (const time in grid[date][cabinetId]) {
          if (!grid[date][cabinetId].hasOwnProperty(time)) continue
          maxLength = Math.max(grid[date][cabinetId][time].length, maxLength)
        }

        const schedule = grid[date][cabinetId]
        const lengths = []

        if (!schedule) { continue }
        for (let k = 1; k < 5; k++) {
          for (let length = maxLength; length > 0; length--) {
            const timesWithLength = []
            // find all schedule time rows with specific length
            for (const time in schedule) {
              if (!schedule.hasOwnProperty(time)) continue
              if (schedule[time].length === length) {
                timesWithLength.push(schedule[time])
              }
            }

            let foundMax

            timesWithLength.forEach((times) => {
              foundMax = -1
              times.forEach((appId) => {
                if (lengths[appId]) {
                  foundMax = Math.max(lengths[appId], foundMax)
                }
              })

              times.forEach((appId) => {
                if (appId.isIgnore() || appId.isFree()) return
                lengths[appId] = lengths[appId]
                  ? Math.max(length, lengths[appId], foundMax)
                  : Math.max(foundMax, length)
              })
            })
          }
        }

        if (!schedule) { continue }
        for (const time in schedule) {
          if (!schedule.hasOwnProperty(time)) continue
          if (schedule[time].length < maxLength) {
            const busyLength = lengths[schedule[time][0]]

            for (let i = schedule[time].length; i < maxLength; i++) {
              schedule[time][i] = (i >= busyLength)
                ? buildGridElement('ignore')
                : buildGridElement('free')
            }
          }
        }
      }
    }
  },

  _getDrawableElements (inst) {
    const god = this

    const drawableElements = []

    Object.values(inst._appointmentsHashMap).forEach((appointment) => {
      drawableElements.push({ ...(god._setAppointmentTime(inst, appointment)), __sg_type: 'appointment' })
    })

    Object.values(inst._reservations).forEach((reservation) => {
      drawableElements.push({ ...(god._setAppointmentTime(inst, reservation)), __sg_type: 'reservation' })
    })

    // sort by time and duration
    drawableElements.sort((ap1, ap2) => {
      const scomp = Utils.stringsCompare
      const step = inst.options.step

      if (ap1.date !== ap2.date) {
        return scomp(ap1.date, ap2.date)
      }

      if (ap1._roughTimeUnix !== ap2._roughTimeUnix) {
        return ap1._roughTimeUnix - ap2._roughTimeUnix
      }

      if (ap1.duration !== ap2.duration) {
        return Math.ceil(ap2.duration / step) - Math.ceil(ap1.duration / step)
      }

      if (ap1.client && ap2.client) {
        return scomp(ap1.client.fullname, ap2.client.fullname)
      }

      if (ap1.__sg_type !== ap2.__sg_type) {
        return scomp(ap1.__sg_type, ap2.__sg_type)
      }

      return 0
    })

    return drawableElements
  },

  _generateAppointmentContainer (inst, cell) {
    const god = this

    return (
      `<div id="${god._generateGridSelector(cell.date, cell.cabinet_id, cell.time)}"
            class="schedule-grid-appointments-container schedule-grid-container-available"></div>`
    )
  },

  _generateGridSelector (date, cabinetId, time) {
    return `cabinet_appointment_container_${date}_${cabinetId}_${time}`
  },
}
