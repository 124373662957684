import flat from 'flat'
import mapKeys from 'lodash/mapKeys'
import forEach from 'lodash/forEach'
import { beforeSendExternal } from '@/plugins/before_send_external/before_send_external'
import { flatObject } from '@/helpers/object_helpers'

(function ($) {
  //var forms = []

  // checkboxes must be true/false
  //todo: serialize for checkboxes not working
  //todo: reset for checkboxes not working

  // ====================== UTILITIES METHODS ==================================

  const setValue = function (elem, value) {
    $.RemoteForm.setValue(elem, value)
  }

  // ====================== END OF UTILITIES METHODS ==================================

  function RemoteForm () {
    this._defaults = {}
  }
  const god = new RemoteForm()
  const propertyName = 'remote-form'

  function getInstance (target) {
    return target.data(propertyName)
  }

  $.extend(RemoteForm.prototype, {
    // initialize form object
    attach (target, options) {
      const inst = {
        target,
        model: options.model,
        options: $.extend({}, options),
        jQM: {
          fields: {},
          submit: options.saveButton ? $(options.saveButton) : target.find(':submit'),
        },
        values: {},
        requestWaiting: false,
      }

      target.data(propertyName, inst)

      god.initFields(inst)
      god.bindEvents(inst)
    },

    // store fields dom nodes
    initFields (inst) {
      inst.target.find('[name^=' + inst.model + ']').each(function (index, field) {
        const $field = $(field)
        if ($field.attr('type') === 'hidden') return
        const name = $field.attr('name')

        inst.jQM.fields[name] = $field
        inst.values[name] = $field.val()

        if (name.match(/ids]\[]$/)) {
          const synonym = name.replace(/\[]$/, '')

          inst.jQM.fields[synonym] = $field
          inst.values[synonym] = $field.val()
        }
      })
    },

    bindEvents (inst) {
      const popupErrors = inst.options.popupErrors
      const popupFields = inst.options.popupFields
      const onSuccess = inst.options.onSuccess
      const onError = inst.options.onError || function () {}
      const beforeSend = inst.options.beforeSend || function () {}
      const afterSend = inst.options.afterSend || function () {}

      inst.target.on('ajax:success', function (event, result) {
        god._hideErrors(inst)
        if (afterSend) afterSend()

        if (!inst.options.skipUpdateInitial) {
          // we have to update our initial fields values
          god.initFields(inst)
        }

        if (onSuccess) {
          // pass unblock to handler
          onSuccess(result, function () {
            god._unblockForm(inst)
          })
        } else {
          if (!(inst.options.cancelRedirect)) {
            // do not unblock form if we redirecting
            Turbolinks.visit(inst.target.attr('action'))
          }
        }
      }).on('ajax:error', function (event, response) {
        const errors = response.responseJSON
        console.debug(errors)
        god._hideErrors(inst)

        /* если будет гореть + бОльшая вложенность, то используй flatObjectRecursive */
        god._showErrors(inst, flatObject(errors), popupErrors, popupFields)

        if (afterSend) afterSend()
        onError(errors)
        god._unblockForm(inst)
      })

      inst.target.on('submit', function (e) {
        god._blockForm(inst)
        beforeSend(e)
        beforeSendExternal(e, inst)
      })
    },

    _fixElemDataValue (attribute, value) {
      if (attribute === 'date' || attribute === 'birthdate' || attribute === 'parent_birthdate') {
        if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
          value = Utils.dateRubyToHuman(value)
        }
      }

      return value
    },

    //==================================== PUBLIC METHDOS ===========================================
    // update form with object
    updateMethod (inst, elemData) {
      god._hideErrors(inst)
      const obj = inst
      const list = []

      const mapped = mapKeys(flat(elemData), (value, key) => {
        return god._translateFromFlatToNameNotation(key)
      })

      forEach(mapped, (value, key) => {
        const attribute = god._getAttributeNameFromNameNotation(key)
        list.push({
          name: obj.model + key,
          value: god._fixElemDataValue(attribute, value),
        })
      })

      list.forEach(function (listItem) {
        const field = obj.jQM.fields[listItem.name]
        if (!field) return
        setValue(field, listItem.value)
      })
    },

    // clear form ( set empty values )
    clearMethod (inst) {
      god._hideErrors(inst)
      const obj = inst
      for (const field in obj.jQM.fields) {
        setValue(obj.jQM.fields[field], '')
      }
    },
    // reset form to values from initital
    resetMethod (inst) {
      god._hideErrors(inst)
      const obj = inst
      $.each(obj.jQM.fields, function (name, field) {
        field.val(obj.values[name]).change()
      })
    },
    // get form params as js object
    serializeMethod (inst) {
      const obj = inst
      const res = {}

      $.each(obj.jQM.fields, function (name, field) {
        let fName = name.replace(inst.model, '')
        fName = fName.replace('[', '')
        fName = fName.replace(']', '')
        if (fName.indexOf('[') === -1) {
          res[fName] = field.val()
        }
      })

      return res
    },

    updateStoredValuesMethod (inst) {
      god.initFields(inst)
    },
    /**
     *
     * @param {object} inst
     * @param {boolean} lock
     */
    blockFormMethod (inst, lock) {
      lock ? this._blockForm(inst) : this._unblockForm(inst)
    },

    _blockForm (inst) {
      inst.jQM.submit.loadSpin('start')
    },

    _unblockForm (inst) {
      inst.jQM.submit.loadSpin('stop')
    },

    _translateFromRailsPathToNameNotation (fieldName) {
      return fieldName
        .replace(/(\[\d+]\.)/g, '_attributes$1') // _attributes for has_many
        .replace(/\]\./g, ']') // dotes of has_many
        .replace(/([a-z_]+)/g, '[$1]')
        .replace(/\]\./g, '_attributes]') //  dotes of has_one
    },

    _translateFromFlatToNameNotation (fieldName) {
      const railsLike = fieldName.replace(/\.(\d+)/, '[$1]')

      return god._translateFromRailsPathToNameNotation(railsLike)
    },

    _getAttributeNameFromNameNotation (name) {
      const match = /\[(\w+)\]$/.exec(name)
      if (match) return match[1]
    },

    _showErrors (inst, errors, popupErrors, popupFields) {
      if (!errors || typeof errors !== 'object') {
        Notificator.error(t('abstract_error_message'))

        return
      }

      for (const fieldName in errors) {
        const htmlFieldName = god._translateFromRailsPathToNameNotation(fieldName)

        if (popupErrors || /_popup_$/.test(fieldName) ||
          (popupFields && popupFields.indexOf(fieldName) !== -1)) {
          errors[fieldName].forEach(function (str) {
            Notificator.error(str)
          })
          continue
        }

        const selector = "[name='" + inst.model + htmlFieldName + "']"
        let elem = inst.target.find(selector)
        // because rails sometimes is very stupid
        if (!elem.length) elem = inst.target.find(selector.replace(/('])$/, '[]$1'))
        // this may be relation
        if (!elem.length) elem = inst.target.find(selector.replace(/(\]'])$/, '_id$1'))
        // for fields like "phone1"
        if (!elem.length) elem = inst.target.find(selector.replace(/\](\d+)/, '$1]'))
        if (!elem.length) {
          elem = inst.target.find('[data-attribute="' + fieldName + '"]')
        }
        // для случая вложенности, в ошибки должно залететь flatObject(errors)
        if (!elem.length) {
          elem = inst.target.find(`#${inst.model}_${fieldName}`)
        }

        if (elem.hasClass('ignore-showing-errors')) continue

        // for hidden fields hack
        if (elem.data('error-pointer')) elem = $(elem.data('error-pointer'))
        if (elem.length === 0) {
          console.debug('not found ', fieldName)
          continue
        }

        elem.parent(':not(label)')
          .addClass('has-error has-feedback')
          .append('<div class="under_field_error">' + errors[fieldName][0] + '</div>')

        if ($(elem).closest('.tab-pane').length) {
          $('a[href="#' + $(elem).closest('.tab-pane').attr('id') + '"]')
            .parent().addClass('tab-has-error')
        }
      }
    },

    // TODO: add form to search
    _hideErrors (inst) {
      // may broke something.. but need in appointments
      //$('.form-control-feedback').remove();
      $('.tab-has-error').removeClass('tab-has-error')
      $('.has-error.has-feedback')
      //.removeClass('has-feedback')   // for appointment form problem!
        .removeClass('has-error')
      $('.under_field_error').remove()
    },
  })

  //==================================== jQyery Plugin ===========================================
  //

  $.RemoteForm = {}

  $.fn.remote_form = function (options) {
    if (typeof options === 'string') { // if passed string, try to call method
      const method = options + 'Method'
      if ($.isFunction(god[method])) {
        const args = Array.prototype.slice.call(arguments, 1)
        args.unshift(getInstance(this))

        return god[method].apply(god, args)
      }
      throw new Error('incorrect public method: ' + method)
    } else { // else init new plugin instance
      god.attach($(this), options || {})
    }
  }

  $.RemoteForm.setValue = function (elem, value) {
    if (elem.attr('type') === 'checkbox') {
      elem[0].checked = !!value
    } else {
      elem.val(value).change()
    }
  }
}(jQuery))
