export default {
  _deleteAppointment (inst, appointment) {
    inst.$target.loadSpin('start')
    $.ajax({
      url: Routes.appointment_path(appointment.id),
      method: 'DELETE',
      success () {
      },
      error (error) {
        const message = Utils.isPermissionError(error)
          ? t('no_permission')
          : t('unable_to_delete_appointment')
        Utils.reportError('requests:_deleteAppointment', message)(error)
      },
      complete () {
        inst.$target.loadSpin('stop')
      },
    })
  },

  _moveAppointmentsToWaitingList (inst, ids) {
    inst.$target.loadSpin('start')
    $.ajax({
      url: Routes.move_to_waiting_list_appointments_path(),
      method: 'POST',
      data: { ids },
      success () {
      },
      error (error) {
        inst.$target.loadSpin('stop')
        const message = Utils.isPermissionError(error)
          ? t('no_permission')
          : t('errors.save_error')
        Utils.reportError('requests:_moveAppointmentsToWaitingList', message)(error)
      },
    })
  },

  _patchAppointment (inst, appointment) {
    inst.$target.loadSpin('start')
    $.ajax({
      url: Routes.appointment_path(appointment.id),
      method: 'PATCH',
      data: { appointment },
      success () {
      },
      error (error) {
        inst.$target.loadSpin('stop')
        if (error.responseJSON) {
          const errors = Object.values(error.responseJSON)
          if (errors.length) {
            errors.forEach((errorMessage) => {
              Notificator.error(errorMessage.join(''))
            })
          }
        } else {
          const message = Utils.isPermissionError(error)
            ? t('no_permission')
            : t('errors.save_error')
          Utils.reportError('requests:_patchAppointment', message)(error)
        }
      },
    })
  },

  _createAppointment (inst, appointment) {
    inst.$target.loadSpin('start')
    $.ajax({
      url: `${Routes.appointments_path()}.json`,
      method: 'POST',
      data: { appointment },
      success () {
      },
      error (error) {
        inst.$target.loadSpin('stop')
        if (error.responseJSON) {
          const errors = Object.keys(error.responseJSON)
          if (errors.length) {
            Notificator.error(errors.join(''))
          }
        } else {
          const message = Utils.isPermissionError(error)
            ? t('no_permission')
            : t('errors.save_error')
          Utils.reportError('requests:_createAppointment', message)(error)
        }
      },
    })
  },
}
