import { ADMINISTRATOR, AVAILABLE, DATE, ICONS, META, TIME, UNAVAILABLE, USERNAME } from '../scheduleCommon/const.js'

const getPhoneTemplate = (cell) => {
  if (gon.page.controller !== 'doctor_schedules') { return '' }

  return cell.phone
    ? `
            <span class="schedule-grid-header-icon ml-5 schedule-grid-header-icon__phone phone_number" data-phone="${cell.phone}" data-no-sms="1" title="${t('call_to_doctor')}">
              <i class="fad fa-phone" data-phone="${cell.phone}" data-no-sms="1"></i>
            </span>
          `
    : `
            <span class="schedule-grid-header-icon ml-5 schedule-grid-header-icon__phone disabled" title="${t('call_to_doctor_not_allowed')}">
              <i class="fad fa-phone"></i>
            </span>
          `
}

export default {

  _redrawAll (inst) {
    const god = this

    god._setGrid(inst)
    god._drawGrid(inst)
    god._redrawAllGridObjects(inst)
    god._setWaitingListCount(inst)
    god._drawTimeLine(inst, true)
    setTimeout(() => god._enableDragAndDrop(inst))
    Services.telephony.reset()
    Utils.updateTooltips(document.querySelector('#doctor_schedule_block'))
  },

  _redrawGridElements (inst) {
    const god = this

    god._redrawAllGridObjects(inst)
    god._enableDragAndDrop(inst)
  },

  _redrawTimeRange (inst, data, selectedElementsCount, selectedElementsModule) {
    const god = this

    const step = selectedElementsCount >= 0 ? inst.options.step : -inst.options.step
    const incrementalTime = data.momentTime.clone().add(step, 'minutes')
    let time

    for (let i = 0; i <= selectedElementsModule; i++) {
      const t = incrementalTime.subtract(step, 'minutes').format('HH:mm')

      if (!data.cellsHashTable[t]) {
        const id = god._getCellId(data.date, data.userId, t)
        const element = document.getElementById(id)
        if (!element) break
        data.cellsHashTable[t] = element
      }

      data.cellsHashTable[t].classList.add('schedule-grid-selected')
      time = t
    }

    const reduceDown = selectedElementsCount <= 0 && time < data.endCellTime
    const reduceTop = selectedElementsCount >= 0 && time > data.endCellTime
    const timeKeys = Object.keys(data.cellsHashTable)

    for (let i = 0; i < timeKeys.length; i++) {
      if ((reduceDown && timeKeys[i] > time) || (reduceTop && timeKeys[i] < time) ||
        (time > data.startCellTime && timeKeys[i] < data.startCellTime) ||
        (time < data.startCellTime && timeKeys[i] > data.startCellTime)) {
        data.cellsHashTable[timeKeys[i]].classList.remove('schedule-grid-selected')
      }
    }

    inst.$table.find('.time-range-container').remove()

    if (selectedElementsModule !== 0) {
      let resultStartTime, resultEndTime

      if (selectedElementsCount > 0) {
        resultStartTime = god._toMoment(inst, data.startCellTime).add(step, 'minutes').format('HH:mm')
        resultEndTime = time
      } else {
        resultStartTime = data.startCellTime
        resultEndTime = god._toMoment(inst, time).subtract(step, 'minutes').format('HH:mm')
      }
      data.cellsHashTable[data.startCellTime].innerHTML += god._generateTimeRangeContainer(resultStartTime)
      data.cellsHashTable[time].innerHTML += god._generateTimeRangeContainer(resultEndTime)

      data.startTime = resultStartTime
      data.endTime = resultEndTime
    }

    data.numberSelected = selectedElementsModule
    data.endCellTime = time
  },

  _clearTimeRange (inst) {
    const god = this

    const data = inst.cache.changeWorkTimeData

    Object.keys(data.cellsHashTable).forEach((time) => {
      data.cellsHashTable[time].classList.remove('schedule-grid-selected')
    })
    inst.$table.find('.time-range-container').remove()
    inst.cache.changeWorkTimeData = undefined
    setTimeout(() => god._enableDragAndDrop(inst))
  },

  _setWaitingListCount (inst) {
    if (!inst.options.waitingListCount) return

    for (const container of inst._waitingListCountContainers) {
      const count = inst.options.waitingListCount[container.dataset.id]
      container.lastElementChild.innerHTML = count
    }
  },

  _setGrid (inst) {
    const god = this

    inst._grid = {}
    inst._gridTime = []

    const step = inst.options.step
    const time = inst.globalStart.clone()
    let stepTime

    inst._grid.date = [{ type: META, value: '' }]
    inst._grid.user = [{ type: META, value: '' }]
    inst._grid.icons = [{ type: META, value: '' }]

    while (time < inst.globalEnd) {
      stepTime = time.format('HH:mm')
      inst._grid[stepTime] = [{ type: TIME, value: stepTime }]
      inst._gridTime.push(stepTime)
      time.add(step, 'minutes')
    }

    inst._dates.forEach((date) => {
      Object
        .keys(inst._schedules[date])
        .map((uid) => inst._usersHashMap[uid])
        .sort((user1, user2) => user1.full_name.localeCompare(user2.full_name))
        .forEach((user) => {
          if (
            inst.options.hideUsersWithoutSchedule &&
            inst._schedules[date][user.id].length === 0
          ) return

          inst._grid.date.push({ type: DATE, value: date })
          inst._grid.user.push({
            type: USERNAME,
            value: user.short_name,
            user_id: user.id,
            date,
          })

          inst._grid.icons.push({
            type: ICONS,
            user_id: user.id,
            date,
            phone: user.phone,
          })

          inst._gridTime.forEach((time) => {
            const available = inst
              ._schedules[date][user.id]
              .some((timeRange) => time >= timeRange[0] && time < timeRange[1])

            inst._grid[time].push({
              type: available ? AVAILABLE : UNAVAILABLE,
              value: '',
              user,
              user_id: user.id,
              time,
              date,
            })
          })
        })
    })

    god._mergeGridDates(inst)
  },

  _drawGrid (inst) {
    const god = this

    const table = inst.$table[0]
    const headRows = []
    const bodyRows = []
    let cells

    inst.$table[0].style.width = '100%'

    // rows with dates and user names
    headRows.push(`<tr>${inst._grid.date.map((date) => god._generateHeaderCell(inst, date)).join('')}</tr>`)
    headRows.push(`<tr>${inst._grid.user.map((user) => god._generateHeaderCell(inst, user)).join('')}</tr>`)
    headRows.push(`<tr class="hidden-print">${inst._grid.icons.map((icons) => god._generateHeaderCell(inst, icons)).join('')}</tr>`)

    const useSeparator = god._useSeparatorFunction(inst)

    inst._gridTime.forEach((time) => {
      useSeparator(true) // reset separator closure

      cells = inst
        ._grid[time]
        .map((cell) => cell.type === TIME ? god._generateTimeCell(cell.value) : god._generateBodyCell(inst, cell, useSeparator()))
        .join('')
      bodyRows.push(`<tr>${cells}</tr>`)
    })

    table.innerHTML = `<thead>${headRows.join('')}</thead><tbody>${bodyRows.join('')}</tbody>`
    inst._thead = inst.$table.find('thead').get(0)
    inst._tbody = inst.$table.find('tbody').get(0)
    inst._waitingListCountContainers = inst._thead.getElementsByClassName('waiting-list-count')
    inst._grid.date.length === 1 ? table.classList.add('empty-table') : table.classList.remove('empty-table')
    god._hideHeaderRowWithIcons(inst)
    inst.$table.scroll()
  },

  _hideHeaderRowWithIcons (inst) {
    const rowWithIcons = inst._thead.children[2]
    if (((inst._thead.offsetWidth - 45) / rowWithIcons.children.length) < 110) {
      rowWithIcons.style.display = 'none'
    }
  },

  _generateHeaderCell (inst, cell) {
    const god = this

    switch (cell.type) {
      case USERNAME:
        return (
          `
          <th>
            <div data-id="${cell.user_id}" class="schedule-grid-header schedule-grid-header-user">
              <span class="tooltip-bottom">${cell.value}</span>
            </div>
          </th>
          `
        )
      case ICONS: {
        const phoneTemplate = getPhoneTemplate(cell)

        return (
          `
          <th>
            <div data-id="${cell.user_id}" class="schedule-grid-header schedule-grid-header-icons">
            <a class="fad fa-calendar hidden-print"
               title="${T.user_worktime}"
               target="_blank"
               data-hintPosition="top-right"
               href="${Routes.schedule_path({ user: cell.user_id })}"></a>
            <a class="fad fa-print tooltip-bottom hidden-print"
               title="${t('reception.print_d_appointments')}"
               target="_blank"
               data-hintPosition="top-right"
               href="${Routes.scheduler_printable_path(cell.user_id, cell.date)}"></a>
            ${inst.options.previewPreset !== ADMINISTRATOR ? '' : god._generateWaitingListCountContainer(cell)}
            ${phoneTemplate}
            </div>
          </th>
          `
        )
      }
      case DATE:
        return (
          `
          <th class="schedule-grid-date" style="flex: ${cell.flex};">
            <div class="schedule-grid-header">
            <span class="formatted-date">${Utils.formatClosestDates(cell.value, 'ddd, DD MMMM').title}</span>
            <span class="moment-date hidden">${moment(cell.value).format('ddd, DD MMMM')}</span>
          </div>
          </th>
          `
        )
      case META:
        return (
          `
          <th>
            <div class="schedule-grid-header">
            </div>
          </th>
          `
        )
      default:
        throw new Error('incorrect cell type')
    }
  },

  _generateWaitingListCountContainer (cell) {
    return `
      <span class="waiting-list-count tooltip-bottom"
            title="${T.waiting_list}"
            data-hintPosition="top-right"
            data-id="${cell.user_id}">
        <span class="waiting-list-count-icon fad fa-clipboard-list"></span>
        <span class="count">0</span>
      </span>
      `
  },

  _generateBodyCell (inst, cell, useSeparatorStyle) {
    const god = this

    const cellId = god._getCellId(cell.date, cell.user_id, cell.time)
    const separator = useSeparatorStyle ? 'schedule-grid-with-divider' : ''
    const appointmentsContainer = god._generateAppointmentContainer(inst, cell)

    switch (cell.type) {
      case AVAILABLE:
        return (
          `
          <td id="${cellId}"
              class="schedule-grid-available ${separator}"
              data-date="${cell.date}"
              data-user_id="${cell.user_id}"
              data-time="${cell.time}">
            <span class="schedule-grid-cell-time">${cell.time}</span>
            <span class="schedule-grid-cell-paste">+</span>
            ${appointmentsContainer}
          </td>
          `
        )
      case UNAVAILABLE:
        return (
          `
          <td id="${god._getCellId(cell.date, cell.user_id, cell.time)}"
              class="schedule-grid-unavailable ${separator}"
              data-date="${cell.date}"
              data-user_id="${cell.user_id}"
              data-time="${cell.time}"
          >${appointmentsContainer}</td>
          `
        )
      default:
        throw new Error('incorrect cell type')
    }
  },

  _generateTimeRangeContainer (time) {
    return `<div class="time-range-container">${time}</div>`
  },
}
