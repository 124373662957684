export default {

  _enableDragAndDrop (inst) {
    const god = this
    if (inst.options.disableDnD) return

    inst.$table.find('.schedule-grid-appointment:not(.disabled-appointment)').draggable({
      start (e, ui) {
        inst.$preview.overpop('hide')
        const $this = $(this)
        $this.css('z-index', 3)
        $this.animate({opacity: 0.9}, 'fast')
        inst._isDragging = true
        $this
          .closest('td')
          .addClass('schedule-grid-no-celltime')
        $this
          .closest('.schedule-grid-appointment-container')
          .addClass('schedule-grid-drag-start-container')
        inst.cache.correctDrop = false
      },

      stop (e, ui) {
        setTimeout(() => {
          inst._isDragging = false
        }, 300)
        const $this = $(this)
        $this.css('z-index', 2)
        $this.animate({opacity: 1}, 'fast')
        $('.schedule-grid-no-celltime')
          .removeClass('schedule-grid-no-celltime')
        $('.schedule-grid-drag-start-container')
          .removeClass('schedule-grid-drag-start-container')

        if (!inst.cache.correctDrop) {
          $this.css({top: 0, left: 0})
        }
      },
      containment: inst.$table.find('tbody'),
      cursorAt: {top: 10, left: 10},
      scroll: true,
      delay: 150,
    })

    // enable appointments for drag
    inst.$table.find('.schedule-grid-available').droppable({
      accept: '.schedule-grid-appointment',
      hoverClass: 'schedule-grid-container-drag-hover',
      tolerance: 'pointer',
      drop (e, ui) {
        inst.cache.correctDrop = true
        const cell = god._getCellData(this)
        const appointment = inst._appointmentsHashMap[parseInt(ui.draggable[0].dataset.id)]
        const comeBack = () => ui.draggable.css({top: 0, left: 0})

        // check if we moving to same destination
        if (appointment.time === cell.time &&
          (cell.user_ids.length === 1 && cell.user_ids.includes(appointment.user_id.toString())) &&
          appointment.date === cell.date) {
          // if so, move back
          comeBack()
        } else {
          // else confirm movement
          const yesEvent = () => {
            god._showUserSelectModal(
              inst,
              true,
              cell.user_ids,
              (userId) => {
                god._moveTo(inst, appointment, cell, userId) || comeBack()
                god._showUserSelectModal(inst, false)
                god._showConfirmationModal(inst, false)
              },
              () => {
                comeBack()
                god._showUserSelectModal(inst, false)
                god._showConfirmationModal(inst, false)
              },
            )
          }
          const noEvent = () => {
            comeBack()
            god._showConfirmationModal(inst, false)
          }

          god._showConfirmationModal(
            inst,
            true,
            T.postponement_of_appointment,
            `${T.move_record_to} ${cell.time}?`,
            yesEvent,
            noEvent,
          )
        }
      },
    })
  },
}
