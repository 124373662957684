import globalEvents from './events/global'
import constructorEvents from './events/constuctor_mode'
import editEvents from './events/edit_mode'

const bindEvents = function () {
  tinymce.PluginManager.add('stopdelete', function (ed) {
    globalEvents(ed)

    // contstuctor mode
    if (ed.settings.back) constructorEvents(ed)

    // editor mode
    if (ed.settings.front == true) editEvents(ed)
  })
}
export default bindEvents
