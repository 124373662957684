import {
  MODE_CLIENT_INSERT,
  MODE_CLONE,
  MODE_DEFAULT,
  MODE_MOVE,
  MODE_PHONE_INSERT,
  MODE_WAITING_LIST_INSERT,
  MODE_WAITING_LIST_MOVE,
} from '../scheduleCommon/const.js'
import Services from '@/services/service_repository_instance'
import { createAdministratorOverpopIcons } from '@/vue_components/doctor_schedules/AdministratorOverpopIcons'

export default {

  _bindEvents (inst) {
    const god = this

    const showDrawableElements = (lastDate) => {
      const elements = inst._drawableElements[inst.cache.lastDate]
      for (let i = 0; elements && i < elements.length; i++) {
        elements[i].classList.remove('schedule-grid-hide-appointment')
      }
      inst.cache.lastDate = lastDate
    }

    inst.$table.on('mouseleave', 'tbody', function () {
      showDrawableElements(undefined)
    })

    inst.$table.on('mouseover', 'tbody', function (e) {
      // ugly code for better performance
      let date
      const t = e.target
      const tp = e.target.parentElement
      const tpp = e.target.parentElement.parentElement

      if (t.tagName === 'TD' && (date = t.dataset.date)) {} else if (tp.tagName === 'TD' && (date = tp.dataset.date)) {} else if (tpp.tagName === 'TD' && (date = tpp.dataset.date)) {} else {
        showDrawableElements(undefined)

        return
      }

      if (date !== inst.cache.lastDate) {
        showDrawableElements(date)
        const elements = inst._drawableElements[date]
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('schedule-grid-hide-appointment')
        }
      }
    })

    inst.$table.on('click', '.schedule-grid-header-cabinet', function (e) {
      if (inst._isDragging) return
      inst.options.selectOneVisibleCabinet(parseInt(e.currentTarget.getAttribute('data-id'), 10))
    })

    inst.$table.on('click', '.schedule-grid-available', function (e) {
      if (inst._isDragging) return
      e.stopPropagation()
      e.preventDefault()
      inst.$preview.overpop('hide')

      const cell = god._getCellData(this)

      const applyEvent = (userId) => {
        if (~[MODE_DEFAULT, MODE_CLIENT_INSERT, MODE_PHONE_INSERT].indexOf(inst.mode)) {
          if (typeof inst.options.onFree === 'function') {
            const userWorkTime = inst._schedules[cell.date][cell.cabinet_id][userId]
            const params = {
              date: cell.date,
              time: cell.time,
              status: 'need_approval',
              user_id: userId,
              user: AppCache.get('user', userId),
              _worktime: userWorkTime,
            }
            inst.options.onFree(params, god._getParams(inst))
          }
        } else if (inst.mode === MODE_MOVE) {
          god._moveTo(inst, inst.cache.activeAppointment, cell, userId)
          god._setMode(inst, MODE_DEFAULT)
        } else if (inst.mode === MODE_CLONE) {
          god._cloneTo(inst, inst.cache.activeAppointment, cell, userId)
        } else if (inst.mode === MODE_WAITING_LIST_INSERT) {
          god._insertTo(inst, inst.cache.activeAppointment, cell, userId)
        } else if (inst.mode === MODE_WAITING_LIST_MOVE) {
          god._moveFromWaitingList(inst, inst.cache.activeAppointment, cell, userId)
        }
        god._showUserSelectModal(inst, false)
      }

      const rejectEvent = () => {
        god._showUserSelectModal(inst, false)
        inst.options.closeInsert()
      }

      god._showUserSelectModal(inst, true, cell.user_ids, applyEvent, rejectEvent)
    })

    inst.$table.on('click', '.schedule-grid-reservation, .disabled-appointment', function (e) {
      e.stopPropagation()
      e.preventDefault()
    })

    inst.$table.on('click', '.schedule-grid-appointment:not(.disabled-appointment)', function (e) {
      if (inst._isDragging) return
      if (inst.mode !== MODE_DEFAULT) return

      e.stopPropagation()
      e.preventDefault()

      const appointmentId = parseInt(this.dataset.id)
      const appointment = inst._appointmentsHashMap[appointmentId]

      if (appointment === inst._previewedAppointment) {
        inst.$preview.overpop('hide')
      } else {
        inst._previewedAppointment = appointment
        inst._$previewedAppointment = $(this)
        inst.$preview
          .empty()
          .append(god.genOverpopContent(inst, appointment))

        const $select = inst.$preview
          .find('.schedule-grid-appointment-status-select')

        // tooltip!
        inst.$preview.find('.note-container, .schedule-grid-preview-entry_type').tooltip({ placement: 'bottom' })
        $select.select2Buttons()
        $select.val(appointment.status).change()

        if (!Services.security.canManageAppointment) {
          $select
            .next()
            .find('.select2buttons-option')
            .addClass('disabled')
        }

        inst.cache.activeAppointment = appointment
        inst.$preview.overpop('show', $(this))
        createAdministratorOverpopIcons('#AdministratorOverpopIcons', appointment.client?.id)
        PubSub.emit('page.specific.overpop_cabinets.show')
        Services.telephony.reset()
        $select.on('change', function () {
          const appointment = inst.cache.activeAppointment
          appointment.status = $select.val()
          god._patchAppointment(inst, appointment)
          inst.$preview.overpop('hide')
        })
      }
    })

    inst.$target.on('scroll', function () {
      if (inst._previewedAppointment) {
        const offsetTop = inst._$previewedAppointment.offset().top
        if (offsetTop > inst.$target.height() ||
          offsetTop < inst.$target.offset().top) {
          inst.$preview.overpop('hide')
        } else {
          inst.$preview.overpop('updatePosition')
        }
      }
    })

    $(document).off('keydown.cabinetScheduleGrid')
    $(document).on('keydown.cabinetScheduleGrid', function (e) {
      if (e.keyCode === 27) {
        inst.$preview.overpop('hide')
        if (inst.mode !== MODE_DEFAULT) {
          god._setMode(inst, MODE_DEFAULT)
        }
      }
    })

    god.bindPreviewEvents(inst)
  },
}
