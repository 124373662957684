import administrator from './administrator.js'
import doctor from './doctor.js'

const previewSets = {
  administrator,
  doctor,
}

const current = (inst) => {
  return previewSets[inst.options.previewPreset]
}

export default {
  genOverpopContent (inst, appointment) {
    const god = this

    if (!current(inst)) return

    return current(inst).genOverpopContent.call(god, inst, appointment)
  },

  bindPreviewEvents (inst) {
    const god = this

    if (!current(inst)) return
    current(inst).bindPreviewEvents.call(god, inst)
  },
}
