export default {

  _showCreateWorkTimeModal (inst, show, options = {}) {
    inst.options.createWorkTimeModalCall(show, {
      applyEvent: options.applyEvent || (() => {}),
      message: options.message || '',
      headerMessage: options.headerMessage || '',
      onlyCreate: options.onlyCreate || false,
      rejectEvent: options.rejectEvent || (() => {}),
    })
  },
}
