import ExcellentExport from 'excellentexport'

window.BetterExport = (function () {
  function checkForNumber (element) {
    const exportNumberClasses = ['price', 'amount', 'sum', 'paid_sum', 'export_number']

    return exportNumberClasses.some((numberClass) => element.hasClass(numberClass))
  }

  function _doFixes (args) {
    var table = args[1]
    var tableClone = $('<table>').appendTo('body').addClass('hidden')[0]

    tableClone.innerHTML = table
      .innerHTML
      .replace(/>\s?(\d+\.\d+)\s?</, function (match, sum) {
        return '>&nbsp;' + sum + '<'
      })

    $(tableClone).find('td').each(function () {
      var _this = $(this)
      if (checkForNumber(_this)) {
        _this.html(function (index, string) {
          return string.replace('.', ',').replace('&nbsp;', '').replace('%', '')
        })
      } else if (_this.hasClass('no_ensp')) {
        _this.html(function (index, string) {
          return string.replace('.', ',').replace('&nbsp;', '').replace('%', '')
        })
      } else {
        _this.html(function (index, string) {
          return string + '&ensp;'
        })
      }
    })

    $(tableClone).find('td.export-icon').each(function () {
      var text = $(this).children().data('original-title')
      $(this).html(text)
    })

    $(tableClone).find('a').each(function () {
      var text = $(this).text()
      $(this).parent('td').html(text)
    })

    $(tableClone).find('.chart-container').remove()

    args[1] = tableClone

    return args
  }

  return {
    csv () {
      var args = Array.prototype.slice.call(arguments)
      ExcellentExport.csv.apply(ExcellentExport, _doFixes(args))
    },

    excel () {
      var args = Array.prototype.slice.call(arguments)
      ExcellentExport.excel.apply(ExcellentExport, _doFixes(args))
    },
  }
})()
