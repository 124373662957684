import 'jquery.fancytree/dist/modules/jquery.fancytree.table'

(function () {
  $.fn.fancyPasteModal = function (params) {
    params = params || {}
    const onSelect = params.onSelect || _.noop
    this.fancytree({
      source: {
        url: params.url,
        cache: false,
      },
      checkbox: true,
      icon: false,
      extensions: ['table'],
      focus: false,
      table: {
        checkboxColumnIdx: 0, // render the checkboxes into the this column index (default: nodeColumnIdx)
        indentation: 16, // indent every node level by 16px
        nodeColumnIdx: 1, // render node expander, icon, and title to this column (default: #0)
      },
      renderColumns (event, data) {
        const node = data.node
        const $tdList = $(node.tr).find('>td')

        if (node.isFolder()) {
          $tdList.eq(2)
            .prop('colspan', 3)
            .nextAll().remove()

          return
        }
        $tdList.eq(2).text(node.data.myCustomData)
      },
      select (event, data) {
        if (data.node.isSelected() || (data.tree.getSelectedNodes().length > 0)) {
          params.pasteModalBtn.removeClass('disabled tooltip-bottom')
        } else {
          params.pasteModalBtn.addClass('disabled tooltip-bottom')
        }
      },
    })
    params.pasteBtn.on('click', function () {
      params.pasteModal.modal('show')
      const tree = $.ui.fancytree.getTree()

      params.selectAllBtn.on('click', function () {
        const rootNode = tree.getRootNode()
        const nodes = rootNode.children

        if (params.selectAllBtn.closest('tr').hasClass('fancytree-selected')) {
          nodes.forEach(function (node) {
            node.setSelected(false)
          })
        } else {
          nodes.forEach(function (node) {
            node.setSelected(true)
          })
        }
        params.selectAllBtn.closest('tr').toggleClass('fancytree-selected')
      })

      params.pasteModalBtn.on('click', function () {
        if (!params.pasteModalBtn.hasClass('disabled')) {
          const data = tree.getSelectedNodes()

          data.forEach(function (entry) {
            entry.data.title = entry.title
            onSelect(entry.data)
            entry.toggleSelected()
          })

          params.selectAllBtn.closest('tr').removeClass('fancytree-selected')
          params.pasteModal.modal('hide')
        }
      })
    })
  }
})()
