import { CLIENT_MODEL, clientBeforeSendActions } from '@/plugins/before_send_external/client/client_before_send_actions'

/**
 * Перехват события submit (аналог beforeSend)
 * Вызывается в app/javascript/src/plugins/remote_form.js
 *
 * @param {Event} e
 * @param {{model: String, values: Object}} remoteFormInst
 */
export const beforeSendExternal = (e, remoteFormInst) => {
  const { model } = remoteFormInst
  switch (model) {
    case CLIENT_MODEL: clientBeforeSendActions(e, remoteFormInst); break
  }
}

/**
 * Упрощение для обращения к атрибуту из values
 *
 * @param {String} model
 * @param {String} field
 * @return {String|undefined} - $model[$field]
 */
export const getFieldFullName = (model, field) => {
  if (!model || !field) { throw new Error('Model or field is empty') }

  return `${model}[${field}]`
}
