export default {
  setLoadDataMethod (inst, data) {
    const god = this

    god._init(inst, data)
    god._redrawAll(inst)
    god._triggerChange(inst)
    inst.cache.request = null
    inst.$target.loadSpin('stop')
  },

  setWaitingListCountMethod (inst, waitingListCount) {
    const god = this

    inst.options.waitingListCount = waitingListCount
    god._setWaitingListCount(inst)
  },

  focusOnAppointmentMethod (inst, appointmentId) {
    inst.options.flickeringAppointmentId = appointmentId
  },

  /**
   * @param inst
   * @param {{ onlyNewPatients: Boolean, statuses: Array, types: Array, clientGroups: Array }} filtrationData
   */
  setFiltrationMethod (inst, filtrationData) {
    inst.filtration = filtrationData
  },
}
