/**
 * @typedef {{id: number, title: string, color: string|null}} AppointmentType
 */

export default {
  _tryAddTypeIndicator (appointmentTemplate, appointment) {
    try {
      const typeIndicator = new AppointmentTypeIndicator(appointment, gon.specific.appointment_types)
      typeIndicator.maybeAddToTemplate(appointmentTemplate)
    } catch (error) {
      Utils.reportError('schedule_common:render_appointments')(error)
    }
  },
}

export class AppointmentTypeIndicator {
  /**
   * @param {object} appointment
   * @param {AppointmentType[]} appointmentTypes
   */
  constructor (appointment, appointmentTypes) {
    this._appointment = appointment
    this._appointmentTypes = appointmentTypes
  }

  /**
   * @param appointmentTemplate
   * @throws {Error}
   */
  maybeAddToTemplate (appointmentTemplate) {
    if (this._shouldShowTypeIndicator) {
      this._addToTemplate(appointmentTemplate)
    }
  }

  /**
   * @return {boolean}
   * @private
   */
  get _shouldShowTypeIndicator () {
    return gon.application.show_appointment_type_color_indicator
  }

  /**
   * @param {object} appointmentTemplate
   * @throws {Error}
   * @private
   */
  _addToTemplate (appointmentTemplate) {
    const appointmentType = this._getAppointmentType()

    appointmentTemplate.appointmentTypeIndicatorColor = appointmentType.color
  }

  /**
   * @return {AppointmentType}
   * @throws {Error}
   * @private
   */
  _getAppointmentType () {
    const appointmentTypeId = this._appointment.appointment_type_id
    const appointmentType = this._appointmentTypes.find((appointmentType) => {
      return appointmentType.id === appointmentTypeId
    })

    if (!appointmentType) {
      throw new Error(`No appointment type with id ${appointmentTypeId}`)
    }

    return appointmentType
  }
}
