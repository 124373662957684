import { request } from '@/lib/transport/request'

/**
 * Запрос на обновление note
 * @param {Number} appointmentId
 * @param {String} note
 * @returns {Promise<*>}
 */
export const updateNote = (appointmentId, note = '') => {
  return request({
    type: 'PATCH',
    url: Routes.appointment_path(appointmentId),
    data: { appointment: { note } },
  }).promise
}
