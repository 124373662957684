import { createVueApp } from '@/vue_components/create_vue_app'
import AdministratorOverpopIcons
  from '@/vue_components/doctor_schedules/AdministratorOverpopIcons/AdministratorOverpopIcons'

export const createAdministratorOverpopIcons = (el, clientId) => {
  return createVueApp({
    el,
    name: 'AdministratorOverpopIconRoot',
    render: (h) => h(AdministratorOverpopIcons, {
      props: {
        clientId,
      },
    }),
  })
}
