(function ($) {
  function ViewInModal () {
    this._defaults = {
      onEditBtn: false,
    }
  }

  const $window = $(window)

  function getInstance (target) {
    return target.data(god.propertyName)
  }

  $.extend(ViewInModal.prototype, {

    className: 'view-in-modal',
    propertyName: 'view_in_modal',

    _attach (target, opts) {
      if (!opts.template) throw new Error('no template passed')

      var render = Handlebars.compile(opts.template)
      var inst = {
        options: {},
        state: {},
        window: target,
        windowBody: target.find('.modal-body'),
        render,
      }

      $.extend(inst.options, god._defaults, opts)

      // nasty hack
      inst.window.on('hidden.bs.modal', function () {
        inst.visible = false
        inst.window.modal('hide')
      })

      inst.editBtn = inst.window.find('.modal-edit')
      inst.deleteBtn = inst.window.find('.modal-delete')
      inst.copyBtn = inst.window.find('.modal-copy')

      target.data(god.propertyName, inst)
      target.addClass(god.className)

      inst.editBtn.on('click', function () {
        if ($.isFunction(inst.options.onEditBtn)) {
          inst.options.onEditBtn(inst.state.showedItem, function () {
            inst.window.modal('hide')
          })
        }
      })

      inst.deleteBtn.on('click', function () {
        if ($.isFunction(inst.options.onDeleteBtn)) {
          inst.options.onDeleteBtn(inst.state.showedItem, function () {
            inst.window.modal('hide')
          })
        }
      })

      inst.copyBtn.on('click', function () {
        if ($.isFunction(inst.options.onCopyBtn)) {
          inst.options.onCopyBtn(inst.state.showedItem, function () {
            inst.window.modal('hide')
          })
        }
      })
    },

    updateWindow (target, item) {
      god.removeLoader(target)
      var inst = getInstance(target)
      inst.state.showedItem = item
      inst.windowBody.empty().append(inst.render(item))
      Utils.updateTooltipsInContainer(inst.windowBody[0])

      if (inst.options.dTable) {
        inst.windowBody.find('.view-in-modal-dtable').dynamicTable({
          previewMode: true,
        })
      }
      $window.resize()
    },

    removeLoader (target) {
      var inst = getInstance(target)
      if (inst.loaderDiv) {
        inst.loaderDiv.loadSpin('stop')
        inst.loaderDiv = null
      }
    },

    showLoader (target) {
      var inst = getInstance(target)

      if (inst.visible) {
        god.freezeMethod(target)
      } else {
        god.removeLoader(target)

        inst.loaderDiv = $('<div>')
        inst.windowBody.empty().append(inst.loaderDiv)
        inst.loaderDiv
          .css('height', 200)
          .loadSpin('start')
      }
    },

    showMethod (target, item) {
      var inst = getInstance(target)
      god.unfreezeMethod(target)

      if (Utils.isPromise(item)) {
        inst.window.modal('show')
        god.showLoader(target)
        item.then((item) => {
          god.updateWindow(target, item)
          god.unfreezeMethod(target)
        })
        inst.visible = true
      } else {
        god.updateWindow(target, item)
        inst.window.modal('show')
        inst.visible = true
        god.unfreezeMethod(target)
      }
    },

    updateMethod: function (target, item) {
      const inst = getInstance(target)

      if (Utils.isPromise(item)) {
        god.showLoader(target)
        item.then((item) => {
          god.updateWindow(target, item)
          god.unfreezeMethod(target)
        })
        inst.visible = true
      } else {
        god.updateWindow(target, item)
        inst.visible = true
        god.unfreezeMethod(target)
      }
    },

    freezeMethod (target) {
      var inst = getInstance(target)
      if (inst.frozen) return

      inst.frozen = true
      inst.windowBody.loadSpin('start')
    },

    unfreezeMethod (target) {
      var inst = getInstance(target)
      if (!inst.frozen) return

      inst.frozen = false
      inst.windowBody.loadSpin('stop')
    },
  })

  var god = new ViewInModal()

  $.fn.viewInModal = function (options) {
    var target = this

    if (typeof options === 'string') {
      var method = options + 'Method'
      if ($.isFunction(god[method])) {
        var args = Array.prototype.slice.call(arguments, 1)
        args.unshift(target)
        god[method].apply(god, args)
      }
    } else {
      god._attach(target, options || {})
    }
  }
})(jQuery)
