export default {
  _useSeparatorFunction (inst) {
    const colspan = inst._grid.date.map((cell) => cell.flex)
    colspan.shift()
    let columnCounter = 1
    let colspanIterator = 0
    let useSeparatorResult

    return (reset = false) => {
      if (reset) {
        columnCounter = 1
        colspanIterator = 0

        return
      }

      useSeparatorResult = columnCounter === colspan[colspanIterator]

      if (useSeparatorResult) {
        columnCounter = 1
        colspanIterator++
      } else {
        columnCounter++
      }

      return useSeparatorResult
    }
  },

  _mergeGridDates (inst) {
    const mergedDates = new Map()
    let date, item

    inst._grid.date.forEach((cell) => {
      date = cell.value
      if (item = mergedDates.get(date)) {
        item.flex = item.flex + 1
      } else {
        cell.flex = 1
        mergedDates.set(date, cell)
      }
    })

    inst._grid.date = Array.from(mergedDates.values())
  },

  _drawTimeLine (inst, force = false) {
    const god = this

    if (force || !inst.options.tableVisibility) window.clearTimeout(inst.cache.timeLineInterval)
    if (!inst.options.tableVisibility) return

    const step = parseInt(inst.options.step, 10)
    const current = moment().format('HH:mm')
    const start = inst._gridTime[0]
    let end = god._toMoment(inst, inst._gridTime[inst._gridTime.length - 1])

    if ((end.hours() * 60 + end.minutes() + step) >= 1440) {
      end = '23:55'
    } else {
      end = end.add(step, 'minutes').format('HH:mm')
    }

    if (start < current && end > current) {
      if (!inst.cache.timeLine) {
        inst.cache.timeLine = document.createElement('div')
        inst.cache.timeLine.classList.add('time-line')
        inst.$target.append(inst.cache.timeLine)
      }

      const theadHeight = inst._thead.clientHeight
      const tbodyHeight = inst._tbody.clientHeight
      const minutesBetweenStartCurrent = Utils.timeRangeMinutes(start, current)
      const minutesBetweenStartEnd = Utils.timeRangeMinutes(start, end)
      const position = Math
        .round(theadHeight + (tbodyHeight * (minutesBetweenStartCurrent * 100 / minutesBetweenStartEnd) / 100))
        .toString()
      inst.cache.timeLine.style.top = `${position}px`
    } else if (inst.cache.timeLine) {
      inst.$target.get(0).removeChild(inst.cache.timeLine)
      inst.cache.timeLine = null
    }

    inst.cache.timeLineInterval = window.setTimeout(() => god._drawTimeLine(inst), 5000)
  },

  _generateTimeCell (time) {
    return `<td class="schedule-grid-time"><div class="time-container">${time}</div></td>`
  },
}
