import EventManager from '../event_manager'

const globalEvents = (ed) => {
  ed.on('mousedown', (evt) => {
    const eventManager = new EventManager(this, evt, ed)
    eventManager.setEmptyChars()
    eventManager.handleMouseDown(this, evt, ed)
  })
  ed.on('mouseup', (evt) => {
    const eventManager = new EventManager(this, evt, ed)
    eventManager.handleMouseUp(this, evt, ed)
  })
  ed.on('SetContent', (event) => {
    const eventManager = new EventManager(this, event, ed)
    eventManager.handleSetContent(this, event, ed)
  })
}

window.onmouseup = () => {
  if (window.unblock) {
    document.querySelectorAll('.t-element').forEach((item) => item.setAttribute('contenteditable', false))
    window.unblock = false
  }
}

export default globalEvents
