import {
  populateAppointment,
} from '../scheduleCommon/utils.js'

import {
  KEEP_RESERVATION_TIMEOUT,
  ADMINISTRATOR,
  APPOINTMENT_PACKET,
  RESERVATION_PACKET,
  WORK_TIME_PACKET,
  CLIENT_PACKET,
  PAYMENTS_ACTION,
  CREATED_ACTION,
  UPDATED_ACTION,
  DELETED_ACTION,
  RESERVE_ACTION,
  CANCEL_ACTION,
} from '../scheduleCommon/const.js'

export default {
  _wsChannelUpdatesHandler (inst, packet) {
    const god = this
    if (packet.type === APPOINTMENT_PACKET) {
      if (packet.batch) {
        god._appointmentsBatchUpdatesHandler(inst, packet)
      } else {
        god._appointmentsUpdatesHandler(inst, packet)
      }
    } else if (packet.type === RESERVATION_PACKET && inst.options.previewPreset === ADMINISTRATOR) {
      switch (packet.action) {
        case RESERVE_ACTION:
          return god._reserveTime(inst, packet.data)
        case CANCEL_ACTION:
          return god._cancelReservation(inst, packet.data)
      }
    } else if (packet.type === WORK_TIME_PACKET) {
      god._workTimeUpdatesHandler(inst, packet)
    } else if (packet.type === CLIENT_PACKET) {
      if (packet.action === PAYMENTS_ACTION) god._clientUpdatesHandler(inst, packet.data)
    }
  },

  _clientUpdatesHandler (inst, packet) {
    const god = this

    const currentClientState = inst._clientsHashMap.get(packet.client_id)

    if (currentClientState && currentClientState.unbilledEntries !== packet.client_with_unbilled_entries) {
      currentClientState.unbilledEntries = packet.client_with_unbilled_entries
      god._redrawGridElements(inst)
    }
  },

  _reserveTime (inst, reservation) {
    const god = this

    if (!god._isElementInTheSchedule(inst, reservation)) return

    const id = reservation.id
    const previousReservation = inst._reservations[reservation.id]
    const needRedraw = !previousReservation ||
      (reservation.duration && (previousReservation.duration !== reservation.duration))

    if (inst._reservations[id]) clearTimeout(inst._reservations[id].timeout)

    reservation.duration = reservation.duration || inst.options.step

    reservation.timeout =
      setTimeout(() => god._cancelReservation(inst, reservation), KEEP_RESERVATION_TIMEOUT)

    inst._reservations[id] = reservation

    needRedraw && god._redrawGridElements(inst)
  },

  _tryRemoveReservation (inst, reservation) {
    if (!reservation) return false
    const id = reservation.id

    const needRedraw = !!(inst._reservations[id])
    if (inst._reservations[id]) clearTimeout(inst._reservations[id].timeout)
    delete inst._reservations[id]

    return needRedraw
  },

  _cancelReservation (inst, reservation) {
    const god = this

    god._tryRemoveReservation(inst, reservation) &&
    god._redrawGridElements(inst)
  },

  _findAppropriateReservation (inst, appointment) {
    return Object.values(inst._reservations).find((candidate) =>
      candidate.clinic_id === appointment.clinic_id &&
      candidate.user_id === appointment.user_id &&
      candidate.date === appointment.date &&
      candidate.time === appointment.time &&
      candidate.initiator.id === appointment.created_by_id)
  },

  _appointmentsBatchUpdatesHandler (inst, {action, data, meta}) {
    const god = this

    let needRedraw = false

    data.forEach((appointment) => {
      let clientWithUnbilledEntries = Object.create(null)
      if (action !== DELETED_ACTION) {
        clientWithUnbilledEntries.client_with_unbilled_entries =
         meta.clients_with_unbilled_entries.includes(appointment.client_id)
      }
      if (god._updateAppointmentsState(inst, action, appointment, clientWithUnbilledEntries)) needRedraw = true
    })

    needRedraw && god._redrawGridElements(inst)
  },

  _appointmentsUpdatesHandler (inst, {action, data, meta}) {
    const god = this

    god._updateAppointmentsState(inst, action, data, meta) && god._redrawGridElements(inst)
  },

  _updateAppointmentsState (inst, action, appointment, meta) {
    const god = this

    if (!Services.security.canManageClientPhone) {
      delete appointment.client.formatted_phone
    }

    Services.pubSub.emitAsync(`DOCTOR_SCHEDULES.APPOINTMENTS.${action.toUpperCase()}`, appointment)

    const inRange = god._isElementInTheSchedule(inst, appointment)
    const oldAppointment = inst._appointmentsHashMap[appointment.id]
    const clientId = appointment.client_id
    appointment = populateAppointment(appointment)
    appointment._recentlyUpdated = true

    if (oldAppointment && (action === DELETED_ACTION || (action === UPDATED_ACTION && !inRange))) {
      inst._clientsHashMap.remove(clientId)

      return delete inst._appointmentsHashMap[appointment.id]
    } else if (inRange && (action === CREATED_ACTION || action === UPDATED_ACTION)) {
      inst._clientsHashMap.set(clientId, meta.client_with_unbilled_entries)
      inst._appointmentsHashMap[appointment.id] = appointment
      const reservation = god._findAppropriateReservation(inst, appointment)
      god._tryRemoveReservation(inst, reservation)

      return true
    }

    return false
  },
}
