class GridElement {
  constructor (type, id) {
    this.type = type
    this.id = id
  }

  isAppointment () {
    return this.type === 'appointment'
  }

  isReservation () {
    return this.type === 'reservation'
  }

  isIgnore () {
    return this.type === 'ignore'
  }

  clone () {
    return buildGridElement(this.type, this.id)
  }

  isFree () {
    return this.type === 'free'
  }

  toString () {
    return `{${this.type}}-{${(this.id || '')}}`
  }
}

const buildGridElement = function (type, id) {
  return new GridElement(type, id)
}

export default buildGridElement
